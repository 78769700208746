import {
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Button,
    Checkbox,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';

const MultiSelectDropdown = ({ options, selectedOptions, setSelectedOptions }) => {
    const handleSelect = (value) => {
        setSelectedOptions((prev) =>
            prev.includes(value) ? prev.filter((item) => item !== value) : [...prev, value]
        );
    };

    return (
        <Menu closeOnSelect={false}>
            <MenuButton
                as={Button}
                rightIcon={<ChevronDownIcon />}
                bg="#11047A"
                color="white"
                _hover={{ bg: '#0e3b73' }}
                _active={{ bg: '#0a2956' }}
            >
                Exclude products
            </MenuButton>
            <MenuList>
                {options.map((option) => (
                    <MenuItem key={option.id}>
                        <Checkbox
                            isChecked={selectedOptions.includes(option.id)}
                            onChange={() => handleSelect(option.id)}
                        >
                            {option.name}
                        </Checkbox>
                    </MenuItem>
                ))}
            </MenuList>
        </Menu>
    );
};

export default MultiSelectDropdown;