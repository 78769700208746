import React, { createContext, useState, useContext, useCallback } from 'react';
import { processBackendErrors } from '../Utils/Utils';

const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
    const [notifications, setNotifications] = useState([]);

    const addNotification = useCallback((message, severity) => {
        setNotifications((prev) => [...prev, { message, severity, key: Date.now() + Math.random() }]);
    }, []);

    const removeNotification = useCallback((key) => {
        setNotifications((prev) => prev.filter((notification) => notification.key !== key));
    }, []);

    const addErrorNotifications = useCallback((errorResponse) => {
        processBackendErrors(errorResponse, addNotification);
    }, [addNotification]);

    return (
        <NotificationContext.Provider value={{ notifications, addNotification, removeNotification, addErrorNotifications }}>
            {children}
        </NotificationContext.Provider>
    );
};

export const useNotification = () => useContext(NotificationContext);
