import React, { useEffect, useState } from 'react';
import {
    Box,
    Text,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Button,
    IconButton,
    useDisclosure,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    SimpleGrid,
    FormControl,
    FormLabel,
    Input,
    Tooltip,
    Flex, Icon,
} from '@chakra-ui/react';
import { DeleteIcon, EditIcon, ViewIcon, RepeatIcon, CopyIcon } from '@chakra-ui/icons';
import { getProducts, deleteProduct } from '../../../api/productsApi';
import { useNavigate } from 'react-router-dom';
import { useNotification } from "../../../contexts/NotificationContext";
import axiosInstance from '../../../api/axiosInstance';
import CopyButton from "../../../components/copyButton/CopyButton";
import Pagination from '../orders/Pagination';
import FormPreview from '../../../components/formPreview/FormPreview';
import {MdSync} from "react-icons/md";

const ProductList = () => {
    const [products, setProducts] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);
    const [pagination, setPagination] = useState({});
    const [loadingProductId, setLoadingProductId] = useState(null);
    const [productToDelete, setProductToDelete] = useState(null);
    const [previewProduct, setPreviewProduct] = useState(null);
    const [shouldSearch, setShouldSearch] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const navigate = useNavigate();
    const { addNotification } = useNotification();

    const {
        isOpen: isDeleteDialogOpen,
        onOpen: onOpenDeleteDialog,
        onClose: onCloseDeleteDialog,
    } = useDisclosure();

    const {
        isOpen: isPreviewDialogOpen,
        onOpen: onOpenPreviewDialog,
        onClose: onClosePreviewDialog,
    } = useDisclosure();

    useEffect(() => {
        fetchProducts();
    }, [currentPage, shouldSearch]);

    const fetchProducts = async () => {
        const response = await getProducts(currentPage, searchTerm);
        setProducts(response.data);
        setCurrentPage(response.current_page);
        setLastPage(response.last_page);
        setPagination(response);
    };

    const handleDelete = async () => {
        if (productToDelete) {
            const response = await deleteProduct(productToDelete.id);
            setProducts(products.filter(product => product.id !== productToDelete.id));
            onCloseDeleteDialog();
            addNotification(response.message, 'success');
        }
    };

    const handlePageChange = (value) => {
        setCurrentPage(value);
    };

    const handleEdit = (id) => {
        navigate(`/admin/edit-product/${id}`);
    };

    const handleDuplicate = (id) => {
        navigate(`/admin/duplicate-product/${id}`);
    };

    const handleClickOpenDeleteDialog = (product) => {
        setProductToDelete(product);
        onOpenDeleteDialog();
    };

    const handleViewForm = async (productId) => {
        setLoadingProductId(productId);
        try {
            const response = await axiosInstance.get(`/products/${productId}/forms`);
            if (response.data.length > 0) {
                navigate(`/admin/forms/${response.data[0].id}/${productId}`);
            } else {
                navigate(`/admin/products/${productId}/create-form`);
            }
        } catch (error) {
            console.error('Error checking form existence:', error);
        } finally {
            setLoadingProductId(null);
        }
    };

    const handlePreviewForm = (product) => {
        if (product.form) {
            setPreviewProduct(product);
            onOpenPreviewDialog();
        } else {
            addNotification('This product does not have a form', 'warning');
        }
    };

    const getScriptShortCode = () => {
        if (!previewProduct) return;

        return `<div id="form-container"></div>
      <script>
        (function(){
          const t=\`v=\${Date.now()}\`,
                e=document.createElement("script");
          e.src=\`${process.env.REACT_APP_API_DOMAIN}/js/formGenerator.js?\${t}\`;
          document.head.appendChild(e);
          e.onload=function(){
            loadForm('${previewProduct.slug}', 'form-container', '${process.env.REACT_APP_API_DOMAIN}', '${previewProduct.api_key}')
          }
        })();
      </script>`;
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
        if (event.target.value === '') {
            setShouldSearch((prev) => prev + 1);
        }
    };

    const handleSearchDatabase = (event) => {
        if (event.key === 'Enter') {
            setShouldSearch((prev) => prev + 1);
        }
    };

    return (
        <Box maxWidth="xxl" px={{ base: '20px', md: '40px' }} py={{ base: '130px', md: '80px' }}>

            <Box bg="white" p={4} boxShadow="lg" rounded="md" mb="20px">
                <SimpleGrid columns={{ base: 1, md: 2, lg: 2, xl: 3 }} spacing="20px">
                    <FormControl>
                        <FormLabel>Search Product</FormLabel>
                        <Input
                            placeholder="Search by name"
                            onChange={handleSearchChange}
                            onKeyPress={handleSearchDatabase}
                            value={searchTerm}
                        />
                    </FormControl>

                    <Box
                        gridColumn={{ base: "span 1", md: "span 2" }}
                        textAlign={{ base: "center", md: "right" }}
                        mt={{ base: "20px", md: "0" }}
                    >
                        <Button
                            colorScheme="blue"
                            leftIcon={<Icon as={MdSync} />}
                            onClick={() => navigate('/admin/create-product')}
                            width={{ base: "full", md: "auto" }}
                        >
                            Create Product
                        </Button>
                    </Box>
                </SimpleGrid>
            </Box>

            <Box overflowX="auto" boxShadow="lg" rounded="md" bg="white">

                <Table variant="simple">
                    <Thead>
                        <Tr>
                            <Th>ID</Th>
                            <Th>Name</Th>
                            <Th>Slug (Unique)</Th>
                            <Th>Product ID</Th>
                            <Th>Options</Th>
                            <Th>Actions</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {products.map((product) => (
                            <Tr key={product.id}>
                                <Td>{product.id}</Td>
                                <Td>{product.name}</Td>
                                <Td>{product.slug}</Td>
                                <Td>{product.product_id}</Td>
                                <Td>
                                    <ul>
                                        {product.options.map(option => (
                                            <li key={option.id}>
                                                {option.name} - {option.value} LEI
                                            </li>
                                        ))}
                                    </ul>
                                </Td>
                                <Td>
                                    <Flex gap="2">
                                        <Tooltip label="Edit Product">
                                            <IconButton
                                                icon={<EditIcon />}
                                                aria-label="Edit Product"
                                                onClick={() => handleEdit(product.id)}
                                            />
                                        </Tooltip>
                                        <Tooltip label="Delete Product">
                                            <IconButton
                                                icon={<DeleteIcon />}
                                                aria-label="Delete Product"
                                                onClick={() => handleClickOpenDeleteDialog(product)}
                                            />
                                        </Tooltip>
                                        <Tooltip label="Duplicate Product">
                                            <IconButton
                                                icon={<CopyIcon />}
                                                aria-label="Duplicate Product"
                                                onClick={() => handleDuplicate(product.id)}
                                            />
                                        </Tooltip>
                                        <Tooltip label="View Form">
                                            <IconButton
                                                icon={<RepeatIcon />}
                                                aria-label="View Form"
                                                onClick={() => handleViewForm(product.id)}
                                                isLoading={loadingProductId === product.id}
                                            />
                                        </Tooltip>
                                        <Tooltip label="Preview Form">
                                            <IconButton
                                                icon={<ViewIcon />}
                                                aria-label="Preview Form"
                                                onClick={() => handlePreviewForm(product)}
                                            />
                                        </Tooltip>
                                    </Flex>
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </Box>

            <Flex justifyContent="space-between" mt="4" flexWrap="wrap">
                <Text>
                    Showing {pagination.to} of {pagination.total} products
                </Text>
                <Pagination totalPages={lastPage} currentPage={currentPage} onPageChange={handlePageChange} />
            </Flex>

            <Modal isOpen={isDeleteDialogOpen} onClose={onCloseDeleteDialog}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Delete Confirmation</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        Are you sure you want to delete this product?
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={onCloseDeleteDialog} mr={3}>
                            Cancel
                        </Button>
                        <Button colorScheme="red" onClick={handleDelete}>
                            Delete
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            <Modal isOpen={isPreviewDialogOpen} onClose={onClosePreviewDialog} size="lg">
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>
                        Form Preview <CopyButton textToCopy={getScriptShortCode()} />
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {previewProduct && (
                            <FormPreview
                                productSlug={previewProduct.slug}
                                apiUrl={`${process.env.REACT_APP_API_DOMAIN}`}
                                apiKey={previewProduct.api_key}
                            />
                        )}
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={onClosePreviewDialog}>
                            Close
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Box>
    );
};

export default ProductList;