import React from 'react';
import { Box, Button, HStack, IconButton, Text, Input } from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
    const isFirstPage = currentPage === 1;
    const isLastPage = currentPage === totalPages;

    const handlePreviousPage = () => {
        if (!isFirstPage) {
            onPageChange(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        if (!isLastPage) {
            onPageChange(currentPage + 1);
        }
    };

    const handlePageInputChange = (event) => {
        const value = parseInt(event.target.value, 10);
        if (value >= 1 && value <= totalPages) {
            onPageChange(value);
        }
    };

    const renderPageNumbers = () => {
        const pageNumbers = [];

        const startPage = Math.max(currentPage - 2, 1);
        const endPage = Math.min(currentPage + 2, totalPages);

        for (let i = startPage; i <= endPage; i++) {
            pageNumbers.push(
                <Button
                    key={i}
                    onClick={() => onPageChange(i)}
                    variant={i === currentPage ? 'solid' : 'outline'}
                    colorScheme="blue"
                >
                    {i}
                </Button>
            );
        }

        return pageNumbers;
    };

    return (
        <Box mt={4} display="flex" justifyContent="space-between" alignItems="center">
            <IconButton
                aria-label="Previous page"
                icon={<ChevronLeftIcon />}
                onClick={handlePreviousPage}
                isDisabled={isFirstPage}
            />

            <HStack spacing={2}>
                {renderPageNumbers()}
            </HStack>

            <IconButton
                aria-label="Next page"
                icon={<ChevronRightIcon />}
                onClick={handleNextPage}
                isDisabled={isLastPage}
            />

            <Box ml={4}>
                <Text display="inline" mr={2}>
                    Go to page:
                </Text>
                <Input
                    width="60px"
                    type="number"
                    min="1"
                    max={totalPages}
                    value={currentPage}
                    onChange={handlePageInputChange}
                />
                <Text ml={2} display="inline">
                    / {totalPages}
                </Text>
            </Box>
        </Box>
    );
};

export default Pagination;