import React from 'react';
import { Box, FormControl, FormLabel, Input, Switch, Flex } from '@chakra-ui/react';

const Step1BasicDetails = ({
                               name,
                               setName,
                               slug,
                               productId,
                               setProductId,
                               shouldSyncWithWooCommerce,
                               setShouldSyncWithWooCommerce,
                               showData,
                               setShowData,
                               useAdvancedOptions,
                               setUseAdvancedOptions,
                               useOptionId,
                               setUseOptionId,
                               useSummary,
                               setUseSummary,
                           }) => (
    <Box minW="80%">
        <FormControl mb="4">
            <FormLabel>Product Name</FormLabel>
            <Input value={name} onChange={(e) => setName(e.target.value)} />
        </FormControl>
        <FormControl mb="4">
            <FormLabel>Slug</FormLabel>
            <Input value={slug} readOnly />
        </FormControl>
        <FormControl mb="4">
            <FormLabel>Product ID</FormLabel>
            <Input value={productId} onChange={(e) => setProductId(e.target.value)} />
        </FormControl>
        <Flex alignItems="center" mb="4">
            <Switch id="woocommerce-sync" isChecked={shouldSyncWithWooCommerce} onChange={(e) => setShouldSyncWithWooCommerce(e.target.checked)} />
            <FormLabel htmlFor="woocommerce-sync" mb="0" ml="2">
                Sync with WooCommerce
            </FormLabel>
        </Flex>
        <Flex alignItems="center" mb="4">
            <Switch id="show-data" isChecked={showData} onChange={(e) => setShowData(e.target.checked)} />
            <FormLabel htmlFor="show-data" mb="0" ml="2">
                Show Orders
            </FormLabel>
        </Flex>
        <Flex alignItems="center" mb="4">
            <Switch id="advanced-options" isChecked={useAdvancedOptions} onChange={(e) => setUseAdvancedOptions(e.target.checked)} />
            <FormLabel htmlFor="advanced-options" mb="0" ml="2">
                Use Advanced Options
            </FormLabel>
        </Flex>
        <Flex alignItems="center" mb="4">
            <Switch id="use-option-id" isChecked={useOptionId} onChange={(e) => setUseOptionId(e.target.checked)} />
            <FormLabel htmlFor="use-option-id" mb="0" ml="2">
                Use Option ID
            </FormLabel>
        </Flex>
        <Flex alignItems="center" mb="4">
            <Switch id="use-summary" isChecked={useSummary} onChange={(e) => setUseSummary(e.target.checked)} />
            <FormLabel htmlFor="use-summary" mb="0" ml="2">
                Use Summary
            </FormLabel>
        </Flex>
    </Box>
);

export default Step1BasicDetails;
