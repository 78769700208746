import React from 'react';
import {
    Box,
    Button,
    FormControl,
    FormLabel,
    Input,
    Flex,
    SimpleGrid,
    IconButton,
    Tooltip,
    Heading,
} from '@chakra-ui/react';
import { DeleteIcon } from '@chakra-ui/icons';

const Step3AdvancedOptions = ({ options, setOptions }) => {
    const handleColorChange = (optionIndex, colorIndex, field, value) => {
        const newOptions = [...options];
        newOptions[optionIndex].colors[colorIndex][field] = value;
        setOptions(newOptions);
    };

    const addColor = (optionIndex) => {
        const newOptions = [...options];
        newOptions[optionIndex].colors.push({ color: '', woocommerce_product_id: '' });
        setOptions(newOptions);
    };

    const removeColor = (optionIndex, colorIndex) => {
        const newOptions = [...options];
        newOptions[optionIndex].colors = newOptions[optionIndex].colors.filter((_, i) => i !== colorIndex);
        setOptions(newOptions);
    };

    return (
        <Box>
            {options.map((option, optionIndex) => (
                <Box key={optionIndex} mb="8" p="12" borderWidth="1px" borderRadius="lg" boxShadow="sm">
                    <Heading as="h4" size="md" mb="4">
                        Option: {option.name || `Option ${optionIndex + 1}`}
                    </Heading>
                    {option.colors?.map((color, colorIndex) => (
                        <SimpleGrid columns={{ base: 1, md: 3 }} spacing="4" mb="4" key={`${optionIndex}-${colorIndex}`}>
                            <FormControl>
                                <FormLabel>Color</FormLabel>
                                <Input
                                    value={color.color}
                                    onChange={(e) => handleColorChange(optionIndex, colorIndex, 'color', e.target.value)}
                                    w="100%"
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>WooCommerce Product ID</FormLabel>
                                <Input
                                    value={color.woocommerce_product_id}
                                    onChange={(e) => handleColorChange(optionIndex, colorIndex, 'woocommerce_product_id', e.target.value)}
                                    w="100%"
                                />
                            </FormControl>
                            <Flex alignItems="end" justifyContent={{ base: 'flex-start' }}>
                                <Tooltip label="Delete Color">
                                    <IconButton
                                        icon={<DeleteIcon />}
                                        onClick={() => removeColor(optionIndex, colorIndex)}
                                        colorScheme="red"
                                        size="sm"
                                    />
                                </Tooltip>
                            </Flex>
                        </SimpleGrid>
                    ))}
                    <Button colorScheme="blue" onClick={() => addColor(optionIndex)} width={{ base: 'full', md: 'auto' }}>
                        Add Color
                    </Button>
                </Box>
            ))}
        </Box>
    );
};

export default Step3AdvancedOptions;
