import React, { createContext, useContext, useEffect, useState } from "react";
import axiosInstance from "../api/axiosInstance";
import notificationSound from '../assets/notification/notification.mp3';

const NotificationsBEContext = createContext();

export const NotificationsBEProvider = ({ children }) => {
    const [notifications, setNotifications] = useState([]);
    const [unreadCount, setUnreadCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);

    const fetchNotifications = async (page = 1) => {
        try {
            const response = await axiosInstance.get(`/notifications?page=${page}`);
            const { data, current_page, last_page } = response.data;

            let newNotifications = [];

            if (page === 1) {
                newNotifications = data;
                setNotifications(data);
            } else {
                newNotifications = [...notifications, ...data];
                setNotifications(prevNotifications => [...prevNotifications, ...data]);
            }

            const unreadCount = data.filter(notification => !notification.is_read).length;
            setUnreadCount(prevUnreadCount => (page === 1 ? unreadCount : prevUnreadCount + unreadCount));

            const lastNotificationId = localStorage.getItem("lastNotificationId");
            const latestNotification = newNotifications.length > 0 ? newNotifications[0] : null;

            if (
                latestNotification &&
                !latestNotification.is_read &&
                (!lastNotificationId || lastNotificationId !== latestNotification.id.toString())
            ) {
                playNotificationSound();

                localStorage.setItem("lastNotificationId", latestNotification.id.toString());
            }

            setHasMore(current_page < last_page);
        } catch (error) {
            console.error("Failed to fetch notifications", error);
        }
    };

    useEffect(() => {
        fetchNotifications();

        const interval = setInterval(() => {
            fetchNotifications(1);
            setCurrentPage(1)
        }, 30000);

        return () => clearInterval(interval);
    }, []);

    const playNotificationSound = () => {
        const sound = new Audio(notificationSound);
        sound.play().catch(error => console.error("Failed to play notification sound", error));
    };

    const markAllAsRead = async () => {
        try {
            await axiosInstance.post("/notifications/mark-all-read");
            setNotifications(prevNotifications =>
                prevNotifications.map(notification => ({ ...notification, is_read: true }))
            );
            setUnreadCount(0);
        } catch (error) {
            console.error("Failed to mark notifications as read", error);
        }
    };

    const fetchMoreNotifications = async () => {
        if (hasMore) {
            const nextPage = currentPage + 1;
            await fetchNotifications(nextPage);
            setCurrentPage(nextPage);
        }
    };

    return (
        <NotificationsBEContext.Provider value={{ notifications, unreadCount, markAllAsRead, fetchMoreNotifications, hasMore }}>
            {children}
        </NotificationsBEContext.Provider>
    );
};

export const useNotifications = () => {
    return useContext(NotificationsBEContext);
};
