import React from 'react';
import {
    IconButton,
    Tooltip,
    useToast,
    Icon,
} from '@chakra-ui/react';
import { MdContentCopy } from 'react-icons/md';

const CopyButton = ({ textToCopy }) => {
    const toast = useToast();

    const handleCopyClick = () => {
        navigator.clipboard.writeText(textToCopy);

        toast({
            title: 'Copied to clipboard',
            status: 'success',
            duration: 3000,
            isClosable: true,
            position: 'bottom-right',
        });
    };

    return (
        <>
            <Tooltip label="Copy script integration code" aria-label="copy-tooltip">
                <IconButton
                    onClick={handleCopyClick}
                    aria-label="copy"
                    icon={<Icon as={MdContentCopy} />}
                    colorScheme="blue"
                    variant="outline"
                    size="md"
                />
            </Tooltip>
        </>
    );
};

export default CopyButton;
