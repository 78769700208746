import React, { useState, useEffect } from 'react';
import {
    Box,
    Text,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Checkbox,
    FormControl,
    FormLabel,
    Input,
    Select,
    Button,
    Flex,
    Tooltip,
    IconButton,
    SimpleGrid,
    useDisclosure,
    Tag, Icon,
} from '@chakra-ui/react';
import {MdDelete, MdAddCall, MdWarning, MdVisibility} from 'react-icons/md';
import { CheckIcon } from '@chakra-ui/icons';
import axiosInstance from '../../../api/axiosInstance';
import dayjs from 'dayjs';
import Pagination from '../orders/Pagination';
import ConfirmationDialog from '../orders/ConfirmationDialog';
import MultiSelectDropdown from '../../../components/multiselect/MultiSelectDropdown';
import Card from 'components/card/Card';

const PartialOrdersPage = () => {
    const [partialOrders, setPartialOrders] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [totalOrders, setTotalOrders] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const [shouldSearch, setShouldSearch] = useState(0);
    const [selectedProduct, setSelectedProduct] = useState('');
    const [selectedProductName, setSelectedProductName] = useState('All products');
    const [products, setProducts] = useState([]);
    const [selectedOrders, setSelectedOrders] = useState([]);
    const [excludedProducts, setExcludedProducts] = useState([]);
    const [chips, setChips] = useState([]);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [orderToDelete, setOrderToDelete] = useState(null);

    useEffect(() => {
        fetchPartialOrders();
    }, [currentPage, shouldSearch, excludedProducts]);

    const fetchPartialOrders = async () => {
        const response = await axiosInstance.get('/reports/partial-orders', {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
            params: {
                page: searchTerm ? 1 : currentPage,
                searchTerm: searchTerm,
                product_id: selectedProduct,
                excluded_ids: excludedProducts,
            },
        });
        setPartialOrders(response.data.orders);
        setTotalPages(response.data.total_pages);
        setTotalOrders(response.data.order_count);
        setProducts(response.data.products);

        if (chips.length === 0) {
            setChips(response.data.products);
        }
    };

    const handlePageChange = (value) => {
        setCurrentPage(value);
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
        if (event.target.value === '') {
            setShouldSearch((prev) => prev + 1);
        }
    };

    const handleSearchDatabase = (event) => {
        if (event.key === 'Enter') {
            setShouldSearch((prev) => prev + 1);
        }
    };

    const handleSelectProduct = async (event) => {
        const productId = event.target.value;
        setSelectedProduct(productId);
        setSelectedProductName(products.find(product => product.id === Number(event.target.value))?.name)

        const response = await axiosInstance.get('/reports/partial-orders', {
            headers: { 'Authorization': `Bearer ${localStorage.getItem('token')}` },
            params: {
                page: currentPage,
                searchTerm: searchTerm,
                product_id: productId
            }
        })

        setPartialOrders(response.data.orders)
        setTotalPages(response.data.total_pages)
        setTotalOrders(response.data.order_count)
        setProducts(response.data.products)
    };

    const handleSelectOrder = (orderId) => {
        setSelectedOrders((prevSelectedOrders) =>
            prevSelectedOrders.includes(orderId)
                ? prevSelectedOrders.filter((id) => id !== orderId)
                : [...prevSelectedOrders, orderId]
        );
    };

    const handleSelectAllOrders = (event) => {
        if (event.target.checked) {
            const allOrderIds = partialOrders.map((order) => order.id);
            setSelectedOrders(allOrderIds);
        } else {
            setSelectedOrders([]);
        }
    };

    const handleDeleteSelected = async () => {
        try {
            await axiosInstance.post('/reports/partial-orders/bulk-delete', { orderIds: selectedOrders }, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
            });
            fetchPartialOrders();
            setSelectedOrders([]);
        } catch (error) {
            console.error('Error deleting partials:', error);
        }
    };

    const handleAddCall = async (order) => {
        try {
            await axiosInstance.get(`/reports/partial-orders/add-call/${order.id}`, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
            });
            fetchPartialOrders();
        } catch (error) {
            console.error('Error adding calls to partials:', error);
        }
    };

    const handleClickOpen = (order) => {
        setOrderToDelete(order);
        onOpen();
    };

    const handleConfirmDelete = async () => {
        if (orderToDelete) {
            try {
                await axiosInstance.delete(`/reports/partial-orders/${orderToDelete.id}`, {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
                });
                fetchPartialOrders();
                onClose();
            } catch (error) {
                console.error('Error deleting partial:', error);
            }
        }
    };

    const buildPartialName = (order) => {
        let name = `${order.option?.name}`;
        if (order.colors && order.colors.length > 0) {
            const colorList = order.colors.join(', ');
            name += ` (${colorList})`;
        }
        return <Box dangerouslySetInnerHTML={{ __html: name }} />;
    };

    return (
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }} maxWidth="xxl" px={{ base: '20px', md: '40px' }}>

            <Card mb="20px" px={{ base: 4, md: 6 }}>
                <SimpleGrid columns={{ base: 1, md: 2, lg: 3, xl: 4 }} spacing="20px">
                    <FormControl>
                        <FormLabel>Product</FormLabel>
                        <Select placeholder="Select Product" w="100%" borderRadius="md" onChange={handleSelectProduct}>
                            {products.map((product) => (
                                <option key={product.id} value={product.id}>
                                    {product.name}
                                </option>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl>
                        <FormLabel>Search</FormLabel>
                        <Input
                            placeholder="Search"
                            w="100%"
                            borderRadius="md"
                            value={searchTerm}
                            onChange={handleSearchChange}
                            onKeyPress={handleSearchDatabase}
                        />
                    </FormControl>

                    <FormControl>
                        <FormLabel>Exclude products from list</FormLabel>
                        <MultiSelectDropdown
                            options={chips}
                            selectedOptions={excludedProducts}
                            setSelectedOptions={setExcludedProducts}
                        />
                    </FormControl>

                    {selectedOrders.length > 0 && (
                        <Flex justifyContent="flex-end" alignItems="end">
                            <Tooltip label="Delete selected partials">
                                <Button colorScheme="red" leftIcon={<Icon as={MdDelete} />} onClick={handleDeleteSelected}>
                                    Delete
                                </Button>
                            </Tooltip>
                        </Flex>
                    )}
                </SimpleGrid>
            </Card>

            <Card>
                <Box overflowX="auto" >
                    <Box display="flex" justifyContent="space-between" mb="4" p="4">
                        <Text fontSize="2xl" fontWeight="bold">
                            {selectedProductName || 'All products'} - {totalOrders}
                        </Text>
                    </Box>

                    <Table variant="simple" size="sm" boxShadow="lg" rounded="md">
                        <Thead>
                            <Tr>
                                <Th>
                                    <Checkbox
                                        isChecked={selectedOrders.length === partialOrders.length}
                                        isIndeterminate={selectedOrders.length > 0 && selectedOrders.length < partialOrders.length}
                                        onChange={handleSelectAllOrders}
                                    />
                                </Th>
                                <Th>ID</Th>
                                <Th>Created At</Th>
                                <Th>Product</Th>
                                <Th>Option</Th>
                                <Th>Name</Th>
                                <Th>Phone</Th>
                                <Th>County</Th>
                                <Th>City</Th>
                                <Th>Address</Th>
                                <Th>Shipment</Th>
                                <Th>Submit Attempt</Th>
                                <Th>Phone Exists</Th>
                                <Th>Number Of Calls</Th>
                                <Th>Actions</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {partialOrders.map((order) => (
                                <Tr key={order.id}>
                                    <Td>
                                        <Checkbox
                                            isChecked={selectedOrders.includes(order.id)}
                                            onChange={() => handleSelectOrder(order.id)}
                                        />
                                    </Td>
                                    <Td>{order.id}</Td>
                                    <Td>{dayjs(order.created_at).format('DD MMMM YYYY HH:mm')}</Td>
                                    <Td>{`${order.quantity} x ${order.product?.name} - ${order.price}`}</Td>
                                    <Td>{buildPartialName(order)}</Td>
                                    <Td>{order.name}</Td>
                                    <Td>{order.phone}</Td>
                                    <Td>{order.county}</Td>
                                    <Td>{order.city}</Td>
                                    <Td>{order.address}</Td>
                                    <Td>{order.shipping}</Td>
                                    <Td>
                                        <Tooltip label={order.submitAttempt ? "Attempted to submit" : "Did not submit"}>
                                        <span>
                                            {order.submitAttempt ? <CheckIcon color="green.500" /> : <MdWarning color="red" />}
                                        </span>
                                        </Tooltip>
                                    </Td>
                                    <Td>
                                        {order.phone_exists && (
                                            <Tooltip label="Phone number exists in orders">
                                                <CheckIcon color="green.500" />
                                            </Tooltip>
                                        )}
                                    </Td>
                                    <Td>
                                        <Tag colorScheme="blue">{order.no_of_calls}</Tag>
                                    </Td>
                                    <Td>
                                        <Flex>
                                            {order.upsells && order.upsells.length > 0 && (
                                                <Tooltip label={`Upsells: ${order.upsells.map(upsell => upsell.name).join(', ')}`}>
                                                    <IconButton
                                                        icon={<MdVisibility />}
                                                        aria-label="View upsells"
                                                        mr="2"
                                                    />
                                                </Tooltip>
                                            )}
                                            <Tooltip label="Add info that you called this partial">
                                                <IconButton
                                                    icon={<MdAddCall />}
                                                    onClick={() => handleAddCall(order)}
                                                    aria-label="Add call"
                                                    mr="2"
                                                />
                                            </Tooltip>
                                            <Tooltip label="Delete partial">
                                                <IconButton
                                                    icon={<MdDelete />}
                                                    onClick={() => handleClickOpen(order)}
                                                    aria-label="Delete order"
                                                />
                                            </Tooltip>
                                        </Flex>
                                    </Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </Box>
            </Card>

            <Flex justifyContent="space-between" mt="4" flexWrap="wrap">
                <Text>
                    Showing {Math.min(totalOrders, 15)} of {totalOrders} orders
                </Text>
                <Pagination totalPages={totalPages} currentPage={currentPage} onPageChange={handlePageChange} />
            </Flex>

            <ConfirmationDialog
                open={isOpen}
                handleClose={onClose}
                handleConfirm={handleConfirmDelete}
                title="Delete Confirmation"
                description="Are you sure you want to delete this partial?"
            />
        </Box>
    );
};

export default PartialOrdersPage;