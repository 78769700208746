export const formatNumber = (number) => {
    return new Intl.NumberFormat('ro-RO').format(number)
}

export const processBackendErrors = (errorResponse, addNotification) => {
    const { errors } = errorResponse

    if (errors && Object.keys(errors).length > 0) {
        Object.keys(errors).forEach((field) => {
            errors[field].forEach((errorMessage) => {
                addNotification(errorMessage, 'error')
            })
        })
    } else {
        addNotification('An error occurred. Please try again.', 'error')
    }
}