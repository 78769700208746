import React, { useState } from "react";
import {
    Box,
    Button,
    Flex,
    Text,
    useColorModeValue,
    Spinner, Icon,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
import BarChart from "components/charts/BarChart";
import {MdBarChart} from "react-icons/md";

export default function ProductTrafficChart({ productTraffic, ...rest }) {
    const [loading, setLoading] = useState(false);
    const iconColor = useColorModeValue("brand.500", "white");
    const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
    const bgHover = useColorModeValue(
        { bg: "secondaryGray.400" },
        { bg: "whiteAlpha.50" }
    );
    const bgFocus = useColorModeValue(
        { bg: "secondaryGray.300" },
        { bg: "whiteAlpha.100" }
    );

    const barChartDataConsumption = [
        {
            name: "Traffic",
            data: productTraffic.map((product) => parseInt(product.total_traffic, 10) || 0),
        },
    ];

    const categories = productTraffic.map((product) => {
        return `#${product.product_id} ${product.name}`;
    });

    const barChartOptionsConsumption = {
        chart: {
            toolbar: {
                show: false,
            },
        },
        xaxis: {
            categories: categories,
            show: true,
            labels: {
                style: {
                    colors: "#A3AED0",
                    fontSize: "14px",
                    fontWeight: "500",
                },
                formatter: function (value) {
                    return value.length > 15 ? `${value.substring(0, 15)}...` : value;
                }
            },
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
        },
        yaxis: {
            labels: {
                show: true,
                style: {
                    colors: "#A3AED0",
                    fontSize: "14px",
                },
            },
        },
        grid: {
            show: false,
            strokeDashArray: 5,
            yaxis: {
                lines: {
                    show: true,
                },
            },
            xaxis: {
                lines: {
                    show: false,
                },
            },
        },
        fill: {
            type: "gradient",
            gradient: {
                type: "vertical",
                shadeIntensity: 1,
                opacityFrom: 0.7,
                opacityTo: 0.9,
            },
        },
        dataLabels: {
            enabled: false,
        },
        plotOptions: {
            bar: {
                borderRadius: 10,
                columnWidth: "40%",
            },
        },
        colors: ["#5E37FF"],
    };

    return (
        <Card align="center" direction="column" w="100%" {...rest}>
            <Flex justify="space-between" align="start" px="10px" pt="5px">
                <Flex flexDirection="column" align="start" me="20px">
                    <Text
                        color="secondaryGray.600"
                        fontSize="sm"
                        fontWeight="500"
                    >
                        Traffic By Product
                    </Text>
                </Flex>
                <Button
                    align="center"
                    justifyContent="center"
                    bg={bgButton}
                    _hover={bgHover}
                    _focus={bgFocus}
                    _active={bgFocus}
                    w="37px"
                    h="37px"
                    lineHeight="100%"
                    borderRadius="10px"
                    {...rest}
                >
                    <Icon as={MdBarChart} color={iconColor} w="24px" h="24px" />
                </Button>
            </Flex>

            <Box h="240px" mt="auto">
                {loading ? (
                    <Flex justify="center" align="center" h="100%">
                        <Spinner size="lg" />
                    </Flex>
                ) : productTraffic.length > 0 ? (
                    <BarChart
                        chartData={barChartDataConsumption}
                        chartOptions={barChartOptionsConsumption}
                    />
                ) : (
                    <Text color="secondaryGray.600" mt="8">
                        No data available for the selected interval.
                    </Text>
                )}
            </Box>
        </Card>
    );
}