import React, { useEffect, useState } from "react";
import { Box, Flex, Button, Text, Spinner } from "@chakra-ui/react";
import BarChart from "components/charts/BarChart";
import Card from "components/card/Card.js";
import axiosInstance from "../../../api/axiosInstance";
import dayjs from "dayjs";

const DailyTraffic = (props) => {
    const { ...rest } = props;
    const [trafficData, setTrafficData] = useState([]);
    const [interval, setInterval] = useState("weekly");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        async function fetchSessionsMetrics() {
            setLoading(true);
            try {
                const response = await axiosInstance.get('/reports/sessions', {
                    params: { interval }
                });

                const sessionData = response.data || [];
                setTrafficData(sessionData);
            } catch (error) {
                console.error("Failed to fetch session metrics", error);
            } finally {
                setLoading(false);
            }
        }

        fetchSessionsMetrics();
    }, [interval]);

    const barChartDataDailyTraffic = [
        {
            name: `${interval === "weekly" ? "Weekly" : "Monthly"} Traffic`,
            data: trafficData.map(item => parseInt(item.total_counter, 10) || 0),
        },
    ];

    const barChartOptionsDailyTraffic = {
        chart: {
            toolbar: {
                show: false,
            },
        },
        xaxis: {
            categories: trafficData.map(item => dayjs(item.day).format('MMM DD') || "N/A"),
            show: true,
            labels: {
                style: {
                    colors: "#A3AED0",
                    fontSize: "14px",
                    fontWeight: "500",
                },
            },
        },
        yaxis: {
            labels: {
                style: {
                    colors: "#CBD5E0",
                    fontSize: "14px",
                },
            },
        },
        grid: {
            show: false,
            strokeDashArray: 5,
            yaxis: {
                lines: {
                    show: true,
                },
            },
            xaxis: {
                lines: {
                    show: false,
                },
            },
        },
        fill: {
            type: "gradient",
            gradient: {
                type: "vertical",
                shadeIntensity: 1,
                opacityFrom: 0.7,
                opacityTo: 0.9,
            },
        },
        dataLabels: {
            enabled: false,
        },
        plotOptions: {
            bar: {
                borderRadius: 10,
                columnWidth: "40px",
            },
        },
    };

    return (
        <Card align='center' direction='column' w='100%' {...rest}>
            <Flex justify='space-between' align='start' px='10px' pt='5px'>
                <Flex flexDirection='column' align='start' me='20px'>
                    <Flex w='100%'>
                        <Text
                            me='auto'
                            color='secondaryGray.600'
                            fontSize='sm'
                            fontWeight='500'>
                            {interval === "weekly" ? "Weekly Traffic" : "Monthly Traffic"}
                        </Text>
                    </Flex>
                </Flex>
                <Flex>
                    <Button
                        onClick={() => setInterval("weekly")}
                        size="sm"
                        colorScheme={interval === "weekly" ? "blue" : "gray"}>
                        Weekly
                    </Button>
                    <Button
                        onClick={() => setInterval("monthly")}
                        size="sm"
                        ml="2"
                        colorScheme={interval === "monthly" ? "blue" : "gray"}>
                        Monthly
                    </Button>
                </Flex>
            </Flex>
            <Box h='240px' mt='auto'>
                {loading ? (
                    <Flex justify="center" align="center" h="100%">
                        <Spinner size="lg" />
                    </Flex>
                ) : trafficData.length > 0 ? (
                    <BarChart
                        key={`${interval}-${trafficData.length}`}
                        chartData={barChartDataDailyTraffic}
                        chartOptions={barChartOptionsDailyTraffic}
                    />
                ) : (
                    <Text color="secondaryGray.600" mt="8">
                        No data available for the selected interval.
                    </Text>
                )}
            </Box>
        </Card>
    );
};

export default DailyTraffic;