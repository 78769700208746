import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from "react-router-dom";
import {
    Box,
    Button,
    VStack,
    Heading,
    Flex,
    useToast,
} from '@chakra-ui/react';
import Stepper from 'react-stepper-horizontal';
import Step1BasicDetails from './Step1BasicDetails';
import Step2Options from './Step2Options';
import Step3AdvancedOptions from './Step3AdvancedOptions';
import Step4Variations from './Step4Variations';
import Step5ReviewSave from './Step5ReviewSave';
import {
    createProduct,
    duplicateProduct,
    showDuplicateProduct,
    showProduct,
    updateProduct,
} from '../../../api/productsApi';
import { useNotification } from '../../../contexts/NotificationContext';

const ProductForm = () => {
    const { id } = useParams();
    const location = useLocation();
    const isDuplicate = location.pathname.includes("duplicate");
    const navigate = useNavigate();
    const { addNotification, addErrorNotifications } = useNotification();

    const [currentStep, setCurrentStep] = useState(0);
    const [name, setName] = useState('');
    const [slug, setSlug] = useState('');
    const [productId, setProductId] = useState('');
    const [options, setOptions] = useState([{ name: '', value: '', quantity: '', catch_phrase: '', image: null, imageUrl: '', reduced_price: '', colors: [{ color: '', woocommerce_product_id: '' }], productIds: [''], preselected: false, free_shipping: false }]);
    const [variations, setVariations] = useState([{ size: '', color: '', image: null, imageUrl: '' }]);
    const [removedImages, setRemovedImages] = useState([]);
    const [formId, setFormId] = useState(null);
    const [shouldSyncWithWooCommerce, setShouldSyncWithWooCommerce] = useState(false);
    const [showData, setShowData] = useState(true);
    const [useAdvancedOptions, setUseAdvancedOptions] = useState(false);
    const [useOptionId, setUseOptionId] = useState(false);
    const [useSummary, setUseSummary] = useState(false);

    useEffect(() => {
        const fetchProduct = async () => {
            try {
                let product;
                if (isDuplicate) {
                    product = await showDuplicateProduct(id);
                    setFormId(product.form?.id);
                } else if (id) {
                    product = await showProduct(id);
                }

                if (product) {
                    if (!isDuplicate) {
                        setName(product.name);
                        setSlug(product.slug);
                        setProductId(product.product_id);
                    }
                    setShouldSyncWithWooCommerce(product.should_sync_with_woocommerce);
                    setShowData(product.show_data);
                    setUseAdvancedOptions(product.use_advanced_options);
                    setUseOptionId(product.use_option_id);
                    setUseSummary(product.use_summary);

                    const processedOptions = product.options.map(option => ({
                        ...option,
                        imageUrl: option.image_url || '',
                        colors: option.colors ? option.colors.map(color => ({
                            id: color.id || '',
                            color: color.color || '',
                            woocommerce_product_id: color.woocommerce_product_id || ''
                        })) : [{ color: '', woocommerce_product_id: '' }],
                        productIds: option.metadata?.product_ids || [''],
                        preselected: option.metadata?.preselected,
                        free_shipping: option.free_shipping,
                    }));

                    if (processedOptions.some(option => Array.isArray(option.productIds) && option.productIds.length > 0 && option.productIds[0] !== '')) setUseOptionId(true);

                    setOptions(processedOptions);

                    setVariations(product.variations.map(variation => ({
                        ...variation,
                        imageUrl: variation.image_url
                    })));
                }
            } catch (error) {
                console.error("Error fetching product:", error);
            }
        };

        fetchProduct();
    }, [id, location.pathname, isDuplicate]);

    const handleNextStep = () => setCurrentStep((prevStep) => prevStep + 1);
    const handlePreviousStep = () => setCurrentStep((prevStep) => prevStep - 1);

    const handleSubmit = async () => {
        const filteredVariations = variations.filter(variation => {
            return variation.size || variation.color || variation.image
        }).map(variation => {
            const filteredVariation = { ...variation }
            if (!filteredVariation.size) {
                delete filteredVariation.size
            }
            if (!filteredVariation.color) {
                delete filteredVariation.color
            }
            return filteredVariation
        })

        const filteredOptions = options.filter(option => {
            return option.name || option.value || option.quantity || option.catch_phrase || option.image
        }).map(option => {
            const filteredOption = { ...option }
            if (!filteredOption.catch_phrase) {
                delete filteredOption.catch_phrase
            }
            if (!filteredOption.reduced_price) {
                delete filteredOption.reduced_price
            }
            if (useOptionId) {
                filteredOption.productIds = option.productIds.filter(pid => pid)
            }
            if (useAdvancedOptions) {
                filteredOption.colors = filteredOption.colors.filter(color => {
                    return color.color && color.woocommerce_product_id
                }).map(color => {
                    const filteredColor = { ...color }
                    if (!filteredColor.id) {
                        delete filteredColor.id
                    }
                    return filteredColor
                })
            }
            return filteredOption
        })

        const productData = {
            name,
            product_id: productId,
            options: filteredOptions,
            variations: filteredVariations,
            removedImages,
            should_sync_with_woocommerce: shouldSyncWithWooCommerce,
            use_advanced_options: useAdvancedOptions,
            show_data: showData,
            use_option_id: useOptionId,
            use_summary: useSummary,
        };

        try {
            if (isDuplicate) {
                await duplicateProduct(id, { ...productData, form_id: formId });
                addNotification('Product duplicated successfully', 'success');
            } else if (id) {
                await updateProduct(id, productData);
                addNotification('Product updated successfully', 'success');
            } else {
                await createProduct(productData);
                addNotification('Product created successfully', 'success');
            }
            navigate('/admin/products');
        } catch (error) {
            addErrorNotifications(error.response?.data);
        }
    };

    const renderStep = () => {
        switch (currentStep) {
            case 0:
                return (
                    <Step1BasicDetails
                        name={name}
                        setName={setName}
                        productId={productId}
                        setProductId={setProductId}
                        shouldSyncWithWooCommerce={shouldSyncWithWooCommerce}
                        setShouldSyncWithWooCommerce={setShouldSyncWithWooCommerce}
                        showData={showData}
                        setShowData={setShowData}
                        setUseAdvancedOptions={setUseAdvancedOptions}
                        useAdvancedOptions={useAdvancedOptions}
                        slug={slug}
                        useOptionId={useOptionId}
                        setUseOptionId={setUseOptionId}
                        useSummary={useSummary}
                        setUseSummary={setUseSummary}
                    />
                );
            case 1:
                return (
                    <Step2Options
                        options={options}
                        setOptions={setOptions}
                        useOptionId={useOptionId}
                    />
                );
            case 2:
                return (
                    <Step3AdvancedOptions
                        options={options}
                        setOptions={setOptions}
                    />
                );
            case 3:
                return (
                    <Step4Variations
                        variations={variations}
                        setVariations={setVariations}
                    />
                );
            case 4:
                return (
                    <Step5ReviewSave
                        name={name}
                        slug={slug}
                        productId={productId}
                        shouldSyncWithWooCommerce={shouldSyncWithWooCommerce}
                        showData={showData}
                        useAdvancedOptions={useAdvancedOptions}
                        useOptionId={useOptionId}
                        options={options}
                        variations={variations}
                        useSummary={useSummary}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <Box bg="white"
             boxShadow="lg"
             rounded="md"
             maxWidth="xxl"
             px={{ base: '20px', md: '40px' }}
             py={{ base: '130px', md: '80px' }}
             mt={{ base: '130px', md: '80px' }}
        >
            <Stepper
                activeStep={currentStep}
                steps={['Basic Details', 'Product Options', 'Advanced Options', 'Variations', 'Review & Save']}
                completeColor="#11047A"
                activeColor="#11047A"
            />
            <VStack spacing="8" mt="8">
                {renderStep()}
            </VStack>
            <Flex justifyContent="space-between" mt="8" direction={{ base: 'column', md: 'row' }} gap={4}>
                <Button onClick={handlePreviousStep} isDisabled={currentStep === 0} colorScheme="red" w={{ base: '100%', md: 'auto' }}>
                    Previous
                </Button>
                <Flex gap={4}>
                    <Button onClick={handleSubmit} colorScheme="teal" w={{ base: '100%', md: 'auto' }}>
                        Save
                    </Button>
                    {currentStep < 4 && (
                        <Button onClick={handleNextStep} colorScheme="blue" w={{ base: '100%', md: 'auto' }}>
                            Next
                        </Button>
                    )}
                </Flex>
            </Flex>
        </Box>
    );
};

export default ProductForm;