import React from 'react';
import {
    Box,
    Button,
    FormControl,
    FormLabel,
    Input,
    Flex,
    SimpleGrid,
    IconButton,
    Tooltip,
    Image,
    Switch,
} from '@chakra-ui/react';
import { DeleteIcon } from '@chakra-ui/icons';

const Step2Options = ({ options, setOptions, useOptionId }) => {
    const handleOptionChange = (index, field, value) => {
        const newOptions = [...options];
        newOptions[index][field] = value;

        if (field === 'quantity' && useOptionId) {
            const quantity = parseInt(value, 10);
            newOptions[index].productIds = new Array(quantity || 1).fill('');
        }

        setOptions(newOptions);
    };

    const handleProductIdChange = (optionIndex, productIndex, value) => {
        const newOptions = [...options];
        newOptions[optionIndex].productIds[productIndex] = value;
        setOptions(newOptions);
    };

    const handleImageChange = (index, event) => {
        const file = event.target.files[0];
        const newOptions = [...options];

        if (file) {
            newOptions[index].image = file;
            newOptions[index].imageUrl = URL.createObjectURL(file);
        } else {
            newOptions[index].image = null;
            newOptions[index].imageUrl = '';
        }

        setOptions(newOptions);
    };

    const addOption = () => {
        setOptions([
            ...options,
            {
                name: '',
                value: '',
                quantity: '',
                catch_phrase: '',
                image: null,
                imageUrl: '',
                reduced_price: '',
                productIds: [''],
                preselected: false,
                free_shipping: false,
            },
        ]);
    };

    const removeOption = (index) => {
        const newOptions = options.filter((_, i) => i !== index);
        setOptions(newOptions);
    };

    return (
        <Box>
            {options.map((option, index) => (
                <Box key={index} mb="8" p="4" borderWidth="1px" borderRadius="lg" boxShadow="sm">
                    <SimpleGrid columns={{ base: 1, md: 3 }} spacing="4" mb="4">
                        <FormControl>
                            <FormLabel>Name</FormLabel>
                            <Input
                                value={option.name}
                                onChange={(e) => handleOptionChange(index, 'name', e.target.value)}
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Value</FormLabel>
                            <Input
                                value={option.value}
                                onChange={(e) => handleOptionChange(index, 'value', e.target.value)}
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Quantity</FormLabel>
                            <Input
                                type="number"
                                value={option.quantity}
                                onChange={(e) => handleOptionChange(index, 'quantity', e.target.value)}
                            />
                        </FormControl>
                    </SimpleGrid>

                    <SimpleGrid columns={{ base: 1, md: 3 }} spacing="4" mb="4">
                        <FormControl>
                            <FormLabel>Catch Phrase</FormLabel>
                            <Input
                                value={option.catch_phrase}
                                onChange={(e) => handleOptionChange(index, 'catch_phrase', e.target.value)}
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Reduced Price</FormLabel>
                            <Input
                                type="number"
                                value={option.reduced_price}
                                onChange={(e) => handleOptionChange(index, 'reduced_price', e.target.value)}
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Upload Image</FormLabel>
                            <Input
                                type="file"
                                accept="image/*"
                                onChange={(e) => handleImageChange(index, e)}
                            />
                            {option.imageUrl && (
                                <Box position="relative" mt="4">
                                    <Image
                                        src={option.imageUrl}
                                        alt={`Option ${index + 1}`}
                                        boxSize="100px"
                                        objectFit="cover"
                                        rounded="md"
                                    />
                                    <IconButton
                                        icon={<DeleteIcon />}
                                        aria-label="delete image"
                                        colorScheme="red"
                                        position="absolute"
                                        top="0"
                                        right="0"
                                        onClick={() => handleImageChange(index, { target: { files: [null] } })}
                                    />
                                </Box>
                            )}
                        </FormControl>
                    </SimpleGrid>

                    {useOptionId &&
                        option.productIds.map((productId, productIndex) => (
                            <FormControl mb="4" key={productIndex}>
                                <FormLabel>Product ID {productIndex + 1}</FormLabel>
                                <Input
                                    value={productId}
                                    onChange={(e) => handleProductIdChange(index, productIndex, e.target.value)}
                                />
                            </FormControl>
                        ))}

                    <Flex alignItems="center" mt="4">
                        <FormControl display="flex" alignItems="center" mb="4">
                            <Switch
                                id={`preselected-${index}`}
                                isChecked={option.preselected}
                                onChange={(e) => handleOptionChange(index, 'preselected', e.target.checked)}
                            />
                            <FormLabel htmlFor={`preselected-${index}`} mb="0" ml="2">
                                Preselected
                            </FormLabel>
                        </FormControl>
                        <FormControl display="flex" alignItems="center" mb="4" ml="4">
                            <Switch
                                id={`free-shipping-${index}`}
                                isChecked={option.free_shipping}
                                onChange={(e) => handleOptionChange(index, 'free_shipping', e.target.checked)}
                            />
                            <FormLabel htmlFor={`free-shipping-${index}`} mb="0" ml="2">
                                Free Shipping
                            </FormLabel>
                        </FormControl>
                        <Tooltip label="Delete Option">
                            <IconButton
                                icon={<DeleteIcon />}
                                onClick={() => removeOption(index)}
                                colorScheme="red"
                                ml="4"
                            />
                        </Tooltip>
                    </Flex>
                </Box>
            ))}
            <Button colorScheme="blue" onClick={addOption}>
                Add Option
            </Button>
        </Box>
    );
};

export default Step2Options;