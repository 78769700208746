import React, { useState } from 'react';
import {
    Avatar,
    Button,
    Flex,
    Icon,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Text,
    useColorModeValue,
    Box,
} from '@chakra-ui/react';
import { MdNotificationsNone } from 'react-icons/md';
import { FaEthereum } from 'react-icons/fa';
import { useAuth } from "../../contexts/AuthContext";
import { useNotifications } from "../../contexts/NotificationsBEContext";
import { ItemContent } from 'components/menu/ItemContent';
import { SidebarResponsive } from 'components/sidebar/Sidebar';
import routes from 'routes';
import dayjs from 'dayjs';

export default function HeaderLinks(props) {
    const { secondary } = props;
    const { notifications, unreadCount, markAllAsRead, fetchMoreNotifications, hasMore } = useNotifications();

    const navbarIcon = useColorModeValue('gray.400', 'white');
    let menuBg = useColorModeValue('white', 'navy.800');
    const textColor = useColorModeValue('secondaryGray.900', 'white');
    const textColorBrand = useColorModeValue('brand.700', 'brand.400');
    const ethColor = useColorModeValue('gray.700', 'white');
    const borderColor = useColorModeValue('#E6ECFA', 'rgba(135, 140, 189, 0.3)');
    const ethBg = useColorModeValue('secondaryGray.300', 'navy.900');
    const ethBox = useColorModeValue('white', 'navy.800');
    const shadow = useColorModeValue(
        '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
        '14px 17px 40px 4px rgba(112, 144, 176, 0.06)',
    );
    const notificationColor = useColorModeValue('red.500', 'red.300');
    const counterColor = useColorModeValue('white', 'black');
    const { user, logout } = useAuth();
    const [loadingMore, setLoadingMore] = useState(false);

    const handleMenuOpen = () => {
        markAllAsRead();
    };

    const handleLoadMore = async () => {
        setLoadingMore(true);
        await fetchMoreNotifications();
        setLoadingMore(false);
    };

    return (
        <Flex
            w={{ sm: '100%', md: 'auto' }}
            alignItems="center"
            flexDirection="row"
            bg={menuBg}
            flexWrap={secondary ? { base: 'wrap', md: 'nowrap' } : 'unset'}
            p="10px"
            borderRadius="30px"
            boxShadow={shadow}
        >
            <Flex
                bg={ethBg}
                display={secondary ? 'flex' : 'none'}
                borderRadius="30px"
                ms="auto"
                p="6px"
                align="center"
                me="6px"
            >
                <Flex
                    align="center"
                    justify="center"
                    bg={ethBox}
                    h="29px"
                    w="29px"
                    borderRadius="30px"
                    me="7px"
                >
                    <Icon color={ethColor} w="9px" h="14px" as={FaEthereum} />
                </Flex>
                <Text
                    w="max-content"
                    color={ethColor}
                    fontSize="sm"
                    fontWeight="700"
                    me="6px"
                >
                    1,924
                    <Text as="span" display={{ base: 'none', md: 'unset' }}>
                        {' '}
                        ETH
                    </Text>
                </Text>
            </Flex>
            <SidebarResponsive routes={routes} />
            <Menu onOpen={handleMenuOpen}>
                <MenuButton p="0px" position="relative">
                    <Icon
                        mt="6px"
                        as={MdNotificationsNone}
                        color={navbarIcon}
                        w="24px"
                        h="24px"
                        me="10px"
                    />
                    {unreadCount > 0 && (
                        <Box
                            position="absolute"
                            top="-5px"
                            right="5px"
                            bg={notificationColor}
                            color={counterColor}
                            borderRadius="full"
                            w="20px"
                            h="20px"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                            fontSize="12px"
                            fontWeight="bold"
                        >
                            {unreadCount}
                        </Box>
                    )}
                </MenuButton>
                <MenuList
                    boxShadow={shadow}
                    p="20px"
                    borderRadius="20px"
                    bg={menuBg}
                    border="none"
                    mt="22px"
                    me={{ base: "30px", md: "unset" }}
                    minW={{ base: "unset", md: "400px", xl: "450px" }}
                    maxW={{ base: "360px", md: "unset" }}
                    maxH='600px'
                    overflowY="auto"
                >
                    <Flex w="100%" mb="20px">
                        <Text fontSize="md" fontWeight="600" color={textColor}>
                            Notifications
                        </Text>
                        <Text
                            fontSize="sm"
                            fontWeight="500"
                            color={textColorBrand}
                            ms="auto"
                            cursor="pointer"
                            onClick={markAllAsRead}
                        >
                            Mark all read
                        </Text>
                    </Flex>
                    <Flex flexDirection="column" mb="10px">
                        {notifications.map((notification) => {
                            const formattedDate = dayjs(notification.created_at).format('MMM D HH:mm');
                            return (
                                <MenuItem
                                    key={notification.id}
                                    _hover={{ bg: "none" }}
                                    _focus={{ bg: "none" }}
                                    px="0"
                                    borderRadius="8px"
                                    mb="10px"
                                >
                                    <ItemContent
                                        info={notification.content}
                                        title={notification.title}
                                        date={formattedDate}
                                    />
                                </MenuItem>
                            );
                        })}
                    </Flex>
                    {hasMore && (
                        <Button
                            onClick={handleLoadMore}
                            isLoading={loadingMore}
                            variant="ghost"
                            colorScheme="blue"
                            w="100%"
                        >
                            Load More
                        </Button>
                    )}
                </MenuList>
            </Menu>
            <Menu>
                <MenuButton p="0px">
                    <Avatar
                        _hover={{ cursor: 'pointer' }}
                        color="white"
                        name={user?.name || 'GForm'}
                        bg="#11047A"
                        size="sm"
                        w="40px"
                        h="40px"
                    />
                </MenuButton>
                <MenuList
                    boxShadow={shadow}
                    p="0px"
                    mt="10px"
                    borderRadius="20px"
                    bg={menuBg}
                    border="none"
                >
                    <Flex w="100%" mb="0px">
                        <Text
                            ps="20px"
                            pt="16px"
                            pb="10px"
                            w="100%"
                            borderBottom="1px solid"
                            borderColor={borderColor}
                            fontSize="sm"
                            fontWeight="700"
                            color={textColor}
                        >
                            👋&nbsp; Hey, {user?.name || 'GForm user'}
                        </Text>
                    </Flex>
                    <Flex flexDirection="column" p="10px">
                        <MenuItem
                            _hover={{ bg: 'none' }}
                            _focus={{ bg: 'none' }}
                            borderRadius="8px"
                            px="14px"
                        >
                            <Text fontSize="sm">Profile Settings</Text>
                        </MenuItem>
                        <MenuItem
                            onClick={() => logout()}
                            _hover={{ bg: 'none' }}
                            _focus={{ bg: 'none' }}
                            color="red.400"
                            borderRadius="8px"
                            px="14px"
                        >
                            <Text fontSize="sm">Log out</Text>
                        </MenuItem>
                    </Flex>
                </MenuList>
            </Menu>
        </Flex>
    );
}