import React from "react";

// Chakra imports
import {Flex} from "@chakra-ui/react";

// Custom components
import {GFormLogo} from "components/icons/Icons";
import {HSeparator} from "components/separator/Separator";

export function SidebarBrand() {

    return (
        <Flex align='center' direction='column'>
            <GFormLogo/>
            <HSeparator mb='20px'/>
        </Flex>
    );
}

export default SidebarBrand;
