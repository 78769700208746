import axiosInstance from './axiosInstance'

export const getProducts = async (page = 1, searchTerm = '') => {
    const response = await axiosInstance.get(`/products?page=${page}&search=${searchTerm}`);
    return response.data;
};

export const duplicateProduct = async (id, productData) => {
    const formData = new FormData();

    formData.append('name', productData.name);
    formData.append('product_id', productData.product_id);
    if (productData.form_id) formData.append('form_id', productData.form_id);
    formData.append('should_sync_with_woocommerce', +productData.should_sync_with_woocommerce);
    formData.append('use_advanced_options', +productData.use_advanced_options);
    formData.append('show_data', +productData.show_data);
    formData.append('use_summary', +productData.use_summary);

    productData.options.forEach((option, index) => {
        if (option.name) formData.append(`options[${index}][name]`, option.name);
        if (option.value) formData.append(`options[${index}][value]`, option.value);
        if (option.quantity) formData.append(`options[${index}][quantity]`, option.quantity);
        if (option.catch_phrase) formData.append(`options[${index}][catch_phrase]`, option.catch_phrase);
        if (option.reduced_price) formData.append(`options[${index}][reduced_price]`, option.reduced_price);
        if (option.preselected) formData.append(`options[${index}][preselected]`, +option.preselected);
        if (option.free_shipping) formData.append(`options[${index}][free_shipping]`, +option.free_shipping);
        if (option.image) {
            formData.append(`options[${index}][image]`, option.image);
        }

        if (productData.use_option_id) {
            option.productIds.forEach((productId, productIndex) => {
                formData.append(`options[${index}][product_ids][${productIndex}]`, productId)
            })
        }

        option.colors?.forEach((color, colorIndex) => {
            if (color.color) formData.append(`options[${index}][colors][${colorIndex}][color]`, color.color);
            if (color.woocommerce_product_id) formData.append(`options[${index}][colors][${colorIndex}][woocommerce_product_id]`, color.woocommerce_product_id);
        });
    });

    productData.variations.forEach((variation, index) => {
        if (variation.size) formData.append(`variations[${index}][size]`, variation.size);
        if (variation.color) formData.append(`variations[${index}][color]`, variation.color);
        if (variation.image) {
            formData.append(`variations[${index}][image]`, variation.image);
        }
    });

    const response = await axiosInstance.post('/products/duplicate', formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });

    return response.data;
};

export const createProduct = async (productData) => {
    const formData = new FormData();

    formData.append('name', productData.name);
    formData.append('product_id', productData.product_id);
    formData.append('should_sync_with_woocommerce', +productData.should_sync_with_woocommerce);
    formData.append('use_advanced_options', +productData.use_advanced_options);
    formData.append('show_data', +productData.show_data);
    formData.append('use_summary', +productData.use_summary);

    productData.options.forEach((option, index) => {
        if (option.name) formData.append(`options[${index}][name]`, option.name);
        if (option.value) formData.append(`options[${index}][value]`, option.value);
        if (option.quantity) formData.append(`options[${index}][quantity]`, option.quantity);
        if (option.catch_phrase) formData.append(`options[${index}][catch_phrase]`, option.catch_phrase);
        if (option.reduced_price) formData.append(`options[${index}][reduced_price]`, option.reduced_price);
        if (option.preselected) formData.append(`options[${index}][preselected]`, +option.preselected);
        if (option.free_shipping) formData.append(`options[${index}][free_shipping]`, +option.free_shipping);
        if (option.image) {
            formData.append(`options[${index}][image]`, option.image);
        }

        if (productData.use_option_id) {
            option.productIds.forEach((productId, productIndex) => {
                formData.append(`options[${index}][product_ids][${productIndex}]`, productId)
            })
        }

        option.colors?.forEach((color, colorIndex) => {
            if (color.color) formData.append(`options[${index}][colors][${colorIndex}][color]`, color.color);
            if (color.woocommerce_product_id) formData.append(`options[${index}][colors][${colorIndex}][woocommerce_product_id]`, color.woocommerce_product_id);
        });
    });

    productData.variations.forEach((variation, index) => {
        if (variation.size) formData.append(`variations[${index}][size]`, variation.size);
        if (variation.color) formData.append(`variations[${index}][color]`, variation.color);
        if (variation.image) {
            formData.append(`variations[${index}][image]`, variation.image);
        }
    });

    const response = await axiosInstance.post('/products', formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });

    return response.data;
};

export const showProduct = async (id) => {
    const response = await axiosInstance.get(`/products/${id}`)
    return response.data
}

export const showDuplicateProduct = async (id) => {
    const response = await axiosInstance.get(`/products/show-duplicate/${id}`)
    return response.data
}

export const updateProduct = async (id, productData) => {
    const formData = new FormData();
    formData.append('name', productData.name);
    formData.append('product_id', productData.product_id);
    formData.append('should_sync_with_woocommerce', +productData.should_sync_with_woocommerce);
    formData.append('use_advanced_options', +productData.use_advanced_options);
    formData.append('show_data', +productData.show_data);
    formData.append('use_summary', +productData.use_summary);

    productData.options.forEach((option, index) => {
        if (option.id) formData.append(`options[${index}][id]`, option.id);
        if (option.name) formData.append(`options[${index}][name]`, option.name);
        if (option.value) formData.append(`options[${index}][value]`, option.value);
        if (option.quantity) formData.append(`options[${index}][quantity]`, option.quantity);
        if (option.catch_phrase) formData.append(`options[${index}][catch_phrase]`, option.catch_phrase);
        if (option.reduced_price) formData.append(`options[${index}][reduced_price]`, option.reduced_price);
        if (option.preselected) formData.append(`options[${index}][preselected]`, +option.preselected);
        if (option.free_shipping) formData.append(`options[${index}][free_shipping]`, +option.free_shipping);
        if (option.image) {
            formData.append(`options[${index}][image]`, option.image);
        }

        if (productData.use_option_id) {
            option.productIds.forEach((productId, productIndex) => {
                formData.append(`options[${index}][product_ids][${productIndex}]`, productId)
            })
        }

        option.colors?.forEach((color, colorIndex) => {
            if (color.id) formData.append(`options[${index}][colors][${colorIndex}][id]`, color.id);
            if (color.color) formData.append(`options[${index}][colors][${colorIndex}][color]`, color.color);
            if (color.woocommerce_product_id) formData.append(`options[${index}][colors][${colorIndex}][woocommerce_product_id]`, color.woocommerce_product_id);
        });
    });

    productData.variations.forEach((variation, index) => {
        if (variation.id) formData.append(`variations[${index}][id]`, variation.id);
        if (variation.size) formData.append(`variations[${index}][size]`, variation.size);
        if (variation.color) formData.append(`variations[${index}][color]`, variation.color);
        if (variation.image) {
            formData.append(`variations[${index}][image]`, variation.image);
        }
    });

    formData.append('_method', 'put');

    const response = await axiosInstance.post(`/products/${id}`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });

    return response.data;
};


export const deleteProduct = async (id) => {
    const response = await axiosInstance.delete(`/products/${id}`)
    return response.data
}