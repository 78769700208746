import React from 'react';
import { Box, Button, Text, Divider, SimpleGrid, Heading, Flex } from '@chakra-ui/react';

const Step5ReviewSave = ({
                             name,
                             productId,
                             shouldSyncWithWooCommerce,
                             showData,
                             useAdvancedOptions,
                             useOptionId,
                             options,
                             variations,
                             useSummary,
                         }) => {
    return (
        <Box>
            <Heading as="h4" size="md" mb="4">
                Product Summary
            </Heading>

            <Box mb="6">
                <Text fontWeight="bold" mb="2">
                    Product Name: <span style={{ fontWeight: 'normal' }}>{name || 'Not specified'}</span>
                </Text>
                <Text fontWeight="bold" mb="2">
                    Product ID: <span style={{ fontWeight: 'normal' }}>{productId || 'Not specified'}</span>
                </Text>
                <Text fontWeight="bold" mb="2">
                    Sync with WooCommerce: <span style={{ fontWeight: 'normal' }}>{shouldSyncWithWooCommerce ? 'Yes' : 'No'}</span>
                </Text>
                <Text fontWeight="bold" mb="2">
                    Show Orders: <span style={{ fontWeight: 'normal' }}>{showData ? 'Yes' : 'No'}</span>
                </Text>
                <Text fontWeight="bold" mb="2">
                    Use Advanced Options: <span style={{ fontWeight: 'normal' }}>{useAdvancedOptions ? 'Yes' : 'No'}</span>
                </Text>
                <Text fontWeight="bold" mb="2">
                    Use Option ID: <span style={{ fontWeight: 'normal' }}>{useOptionId ? 'Yes' : 'No'}</span>
                </Text>
                <Text fontWeight="bold" mb="2">
                    Use Summary: <span style={{ fontWeight: 'normal' }}>{useSummary ? 'Yes' : 'No'}</span>
                </Text>
            </Box>

            <Divider my="4" />

            <Heading as="h4" size="md" mb="4">
                Product Options
            </Heading>

            {options.length > 0 ? (
                options.map((option, index) => (
                    <Box key={index} mb="6" p="4" borderWidth="1px" borderRadius="lg">
                        <Text fontWeight="bold" mb="2">Option {index + 1}</Text>
                        <SimpleGrid columns={{ base: 1, md: 2 }} spacing="4">
                            <Text>Name: <span style={{ fontWeight: 'normal' }}>{option.name || 'Not specified'}</span></Text>
                            <Text>Value: <span style={{ fontWeight: 'normal' }}>{option.value || 'Not specified'}</span></Text>
                            <Text>Quantity: <span style={{ fontWeight: 'normal' }}>{option.quantity || 'Not specified'}</span></Text>
                            <Text>Catch Phrase: <span style={{ fontWeight: 'normal' }}>{option.catch_phrase || 'Not specified'}</span></Text>
                            <Text>Reduced Price: <span style={{ fontWeight: 'normal' }}>{option.reduced_price || 'Not specified'}</span></Text>
                            <Text>Preselected: <span style={{ fontWeight: 'normal' }}>{option.preselected ? 'Yes' : 'No'}</span></Text>
                            <Text>Free shipping: <span style={{ fontWeight: 'normal' }}>{option.free_shipping ? 'Yes' : 'No'}</span></Text>

                            {useOptionId && option.productIds.length > 0 && (
                                <Text>Product IDs: <span style={{ fontWeight: 'normal' }}>{option.productIds.join(', ')}</span></Text>
                            )}

                            {option.colors?.length > 0 && (
                                <Box>
                                    <Text fontWeight="bold" mt="4">Colors:</Text>
                                    {option.colors.map((color, colorIndex) => (
                                        <Text key={colorIndex}>
                                            - {color.color || 'No color specified'} (WooCommerce Product ID: {color.woocommerce_product_id || 'N/A'})
                                        </Text>
                                    ))}
                                </Box>
                            )}

                            {option.imageUrl && (
                                <Box>
                                    <Text>Image: </Text>
                                    <img src={option.imageUrl} alt={`Option ${index + 1}`} width="100px" height="100px" />
                                </Box>
                            )}
                        </SimpleGrid>
                    </Box>
                ))
            ) : (
                <Text>No product options specified.</Text>
            )}

            <Divider my="4" />

            <Heading as="h4" size="md" mb="4">
                Product Variations
            </Heading>

            {variations.length > 0 ? (
                variations.map((variation, index) => (
                    <Box key={index} mb="6" p="4" borderWidth="1px" borderRadius="lg">
                        <Text fontWeight="bold" mb="2">Variation {index + 1}</Text>
                        <SimpleGrid columns={{ base: 1, md: 2 }} spacing="4">
                            <Text>Size: <span style={{ fontWeight: 'normal' }}>{variation.size || 'Not specified'}</span></Text>
                            <Text>Color: <span style={{ fontWeight: 'normal' }}>{variation.color || 'Not specified'}</span></Text>

                            {variation.imageUrl && (
                                <Box>
                                    <Text>Image: </Text>
                                    <img src={variation.imageUrl} alt={`Variation ${index + 1}`} width="100px" height="100px" />
                                </Box>
                            )}
                        </SimpleGrid>
                    </Box>
                ))
            ) : (
                <Text>No product variations specified.</Text>
            )}

            <Divider my="6" />
        </Box>
    );
};

export default Step5ReviewSave;