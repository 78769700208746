import './assets/css/App.css';
import {Routes, Route, Navigate } from 'react-router-dom';
import AuthLayout from './layouts/auth';
import AdminLayout from './layouts/admin';
import {
    ChakraProvider
} from '@chakra-ui/react';
import initialTheme from './theme/theme';
import {useState} from 'react';
import PrivateRoute from "./layouts/admin/PrivateRoute";
import {AuthProvider} from "./contexts/AuthContext";
import {NotificationProvider} from "./contexts/NotificationContext";
import Notification from "./components/notification";
import {NotificationsBEProvider} from "./contexts/NotificationsBEContext";

export default function Main() {

    const [currentTheme, setCurrentTheme] = useState(initialTheme);
    return (
        <ChakraProvider theme={currentTheme}>
            <NotificationsBEProvider>
                <NotificationProvider>
                    <AuthProvider>
                        <Routes>
                            <Route
                                path="admin/*"
                                element={
                                    <PrivateRoute>
                                        <AdminLayout theme={currentTheme} setTheme={setCurrentTheme}/>
                                    </PrivateRoute>
                                }
                            />
                            <Route path="auth/*" element={<AuthLayout/>}/>
                            <Route path="/" element={<Navigate to="/admin/default" replace/>}/>
                        </Routes>
                    </AuthProvider>
                    <Notification/>
                </NotificationProvider>
            </NotificationsBEProvider>
        </ChakraProvider>
    );
}
