import React, { useState, useEffect } from "react";
import {
    Box,
    Flex,
    Icon,
    Button,
    Text,
    useColorModeValue,
    Spinner,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
import LineChart from "components/charts/LineChart";
import axiosInstance from "../../../api/axiosInstance";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {IoCheckmarkCircle, IoCloseCircle} from "react-icons/io5";
import { MdBarChart, MdOutlineCalendarToday } from "react-icons/md";
import { RiArrowUpSFill, RiArrowDownSFill } from "react-icons/ri";
import {formatNumber} from "../../../Utils/Utils";
import dayjs from "dayjs";

export default function TotalSpent(props) {
    const { ...rest } = props;

    const [loading, setLoading] = useState(false);
    const [salesData, setSalesData] = useState([]);
    const [growthPercentage, setGrowthPercentage] = useState(0);
    const [interval, setInterval] = useState("monthly");
    const [selectedDate, setSelectedDate] = useState(new Date());

    const textColor = useColorModeValue("secondaryGray.900", "white");
    const textColorSecondary = useColorModeValue("secondaryGray.600", "white");
    const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
    const iconColor = useColorModeValue("brand.500", "white");
    const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
    const bgHover = useColorModeValue(
        { bg: "secondaryGray.400" },
        { bg: "whiteAlpha.50" }
    );
    const bgFocus = useColorModeValue(
        { bg: "secondaryGray.300" },
        { bg: "whiteAlpha.100" }
    );

    useEffect(() => {
        async function fetchSalesMetrics() {
            setLoading(true);
            try {
                let startDate, endDate;
                if (interval === "monthly") {
                    startDate = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), 1);
                    endDate = new Date(selectedDate.getFullYear(), selectedDate.getMonth() + 1, 0);
                } else if (interval === "weekly") {
                    startDate = new Date(new Date().setDate(new Date().getDate() - 6));
                    endDate = new Date();
                }

                const response = await axiosInstance.get("/reports/daily-sales", {
                    params: {
                        start_date: startDate.toISOString().split("T")[0],
                        end_date: endDate.toISOString().split("T")[0],
                    },
                });
                const salesMetrics = response.data?.sales_data || [];
                setSalesData(salesMetrics);
                setGrowthPercentage(response.data?.growth_percentage || 0);
            } catch (error) {
                console.error("Failed to fetch sales metrics", error);
            } finally {
                setLoading(false);
            }
        }

        fetchSalesMetrics();
    }, [interval, selectedDate]);

    const lineChartDataTotalSpent = [
        {
            name: `${interval === "weekly" ? "Weekly" : "Monthly"} Sales`,
            data: salesData.map((item) => parseFloat(item.total_sales) || 0),
        },
    ];

    const lineChartOptionsTotalSpent = {
        chart: {
            toolbar: {
                show: false,
            },
            dropShadow: {
                enabled: true,
                top: 13,
                left: 0,
                blur: 10,
                opacity: 0.1,
                color: "#4318FF",
            },
        },
        colors: ["#4318FF", "#39B8FF"],
        markers: {
            size: 0,
            colors: "white",
            strokeColors: "#7551FF",
            strokeWidth: 3,
            strokeOpacity: 0.9,
            strokeDashArray: 0,
            fillOpacity: 1,
            discrete: [],
            shape: "circle",
            radius: 2,
            offsetX: 0,
            offsetY: 0,
            showNullDataPoints: true,
        },
        tooltip: {
            theme: "dark",
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            curve: "smooth",
            type: "line",
        },
        xaxis: {
            type: "category",
            categories: salesData.map((item) => dayjs(item.day).format('MMM DD') || "N/A"),
            labels: {
                style: {
                    colors: "#A3AED0",
                    fontSize: "12px",
                    fontWeight: "500",
                },
            },
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
        },
        yaxis: {
            labels: {
                style: {
                    colors: "#CBD5E0",
                    fontSize: "14px",
                },
            },
        },
        legend: {
            show: false,
        },
        grid: {
            show: false,
            column: {
                color: ["#7551FF", "#39B8FF"],
                opacity: 0.5,
            },
        },
        color: ["#7551FF", "#39B8FF"],
    };

    return (
        <Card
            justifyContent="center"
            align="center"
            direction="column"
            w="100%"
            mb="0px"
            {...rest}
        >
            <Flex justify="space-between" ps="0px" pe="20px" pt="5px">
                <Flex align="center" w="100%">
                    {interval === "monthly" ? (
                        <Box>
                            <DatePicker
                                selected={selectedDate}
                                onChange={(date) => setSelectedDate(date)}
                                dateFormat="MM/yyyy"
                                showMonthYearPicker
                                showFullMonthYearPicker
                                showFourColumnMonthYearPicker
                                customInput={
                                    <Button
                                        bg={boxBg}
                                        fontSize="sm"
                                        fontWeight="500"
                                        color={textColorSecondary}
                                        borderRadius="7px"
                                        leftIcon={<MdOutlineCalendarToday color={textColorSecondary} />}
                                    >
                                        {selectedDate.toLocaleString("default", {
                                            month: "long",
                                            year: "numeric",
                                        })}
                                    </Button>
                                }
                            />
                        </Box>
                    ) : (
                        <Button
                            bg={boxBg}
                            fontSize="sm"
                            fontWeight="500"
                            color={textColorSecondary}
                            borderRadius="7px"
                        >
                            <Icon
                                as={MdOutlineCalendarToday}
                                color={textColorSecondary}
                                me="4px"
                            />
                            This week
                        </Button>
                    )}
                    <Button
                        ms="auto"
                        align="center"
                        justifyContent="center"
                        bg={bgButton}
                        _hover={bgHover}
                        _focus={bgFocus}
                        _active={bgFocus}
                        w="37px"
                        h="37px"
                        lineHeight="100%"
                        borderRadius="10px"
                        {...rest}
                    >
                        <Icon as={MdBarChart} color={iconColor} w="24px" h="24px" />
                    </Button>
                </Flex>
            </Flex>
            <Flex w="100%" flexDirection={{ base: "column", lg: "row" }}>
                <Flex flexDirection="column" me="20px" mt="28px">
                    <Text
                        color={textColor}
                        fontSize="34px"
                        textAlign="start"
                        fontWeight="700"
                        lineHeight="100%"
                    >
                        {formatNumber(salesData.reduce((total, item) => total + parseFloat(item.total_sales || 0), 0).toFixed(2))} RON
                    </Text>
                    <Flex align="center" mb="20px">
                        <Text
                            color="secondaryGray.600"
                            fontSize="sm"
                            fontWeight="500"
                            mt="4px"
                            me="12px"
                        >
                            Total Sales
                        </Text>
                        <Flex align="center">
                            <Icon as={growthPercentage > 0 ? RiArrowUpSFill : RiArrowDownSFill} color={growthPercentage >= 0 ? "green.500" : "red.500"} me="2px" mt="2px" />
                            <Text color={growthPercentage > 0 ? "green.500" : "red.500"} fontSize="sm" fontWeight="700">
                                {growthPercentage.toFixed(2)}%
                            </Text>
                        </Flex>
                    </Flex>
                    <Flex align="center">
                        <Icon as={growthPercentage > 0 ? IoCheckmarkCircle : IoCloseCircle} color={growthPercentage >= 0 ? "green.500" : "red.500"} me="4px" />
                        <Text color={growthPercentage > 0 ? "green.500" : "red.500"} fontSize="md" fontWeight="700">
                            {growthPercentage > 0 ? "On track" : "Not so good.."}
                        </Text>
                    </Flex>
                </Flex>
                <Box minH="260px" minW="75%" mt="auto">
                    {loading ? (
                        <Flex justify="center" align="center" h="100%">
                            <Spinner size="lg" />
                        </Flex>
                    ) : (
                        <LineChart
                            chartData={lineChartDataTotalSpent}
                            chartOptions={lineChartOptionsTotalSpent}
                        />
                    )}
                </Box>
            </Flex>
        </Card>
    );
}