import React from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Box,
    Text,
    useDisclosure,
} from '@chakra-ui/react';

const UpsellModal = ({ open, handleClose, upsellDetails, upsellAmount }) => {
    return (
        <Modal isOpen={open} onClose={handleClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Upsell Details</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    {upsellDetails && upsellDetails.length > 0 && (
                        <Box mt={2}>
                            <Text fontWeight="bold">Product Name:</Text>
                            <Text mb={2}>{upsellDetails[0].name}</Text>

                            <Text fontWeight="bold">Upsell Amount:</Text>
                            <Text mb={2}>{upsellAmount} RON</Text>

                            {upsellDetails[0].options && upsellDetails[0].options.length > 0 && (
                                <>
                                    <Text fontWeight="bold">Quantity:</Text>
                                    <Text>{upsellDetails[0].options[0].quantity}</Text>
                                </>
                            )}
                        </Box>
                    )}
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default UpsellModal;
