import React, { useEffect, useState, useRef } from "react";
import {
    Box,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Input,
    Flex,
    Text,
    Spinner,
    useColorModeValue,
    Button,
    Card,
    FormControl,
    FormLabel,
    SimpleGrid,
    Select, useBreakpointValue, Tfoot,
} from "@chakra-ui/react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./custom_style.css";
import axiosInstance from "../../../api/axiosInstance";
import { useNotification } from "../../../contexts/NotificationContext";
import dayjs from "dayjs";
import {formatNumber} from "../../../Utils/Utils";

const SpendDashboard = () => {
    const today = dayjs().toDate();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [startDate, setStartDate] = useState(today);
    const [endDate, setEndDate] = useState(today);
    const [pagination, setPagination] = useState({ current_page: 1, total_pages: 1 });
    const [selectedMonth, setSelectedMonth] = useState('');
    const textColor = useColorModeValue("secondaryGray.900", "white");
    const cardBg = useColorModeValue("white", "navy.800");
    const borderColor = useColorModeValue("#E6ECFA", "rgba(135, 140, 189, 0.3)");
    const { addNotification, addErrorNotifications } = useNotification();
    const leftOffset = useBreakpointValue({ base: "0", md: "300px" });

    const initialFetchDone = useRef(false);

    useEffect(() => {
        if (!initialFetchDone.current) {
            const start = dayjs(today).format("YYYY-MM-DD");
            const end = dayjs(today).format("YYYY-MM-DD");
            fetchData(start, end);
            initialFetchDone.current = true;
        } else if (startDate && endDate && startDate <= endDate) {
            const start = dayjs(startDate).format("YYYY-MM-DD");
            const end = dayjs(endDate).format("YYYY-MM-DD");
            if (Number(Math.abs(endDate - startDate) / (1000 * 60 * 60 * 24)).toFixed() > 31) {
                addNotification("Please select a date range of up to 31 days", "warning");
            } else {
                fetchData(start, end);
            }
        }
    }, [startDate, endDate]);

    const fetchData = async (start, end, page = 1) => {
        setLoading(true);
        try {
            let response;
            if (start && end) {
                response = await axiosInstance.get(`/roas-products?start_date=${start}&end_date=${end}&page=${page}`);
            } else {
                response = await axiosInstance.get(`/roas-products?page=${page}`);
            }

            setData(response.data.data);
            setPagination(response.data.pagination);
        } catch (error) {
            console.error("Error fetching data", error);
        } finally {
            setLoading(false);
        }
    };

    const handleSpendChange = (value, productIndex, dayIndex) => {
        const newData = [...data];
        newData[productIndex].daily_data[dayIndex].spend = value;
        const spend = parseFloat(value);
        const sales = parseFloat(newData[productIndex].daily_data[dayIndex].sales);
        newData[productIndex].daily_data[dayIndex].roas = spend > 0 ? (sales / spend).toFixed(2) : "#DIV/0!";
        setData(newData);
    };

    const handleSaveSingle = async (productIndex, dayIndex) => {
        const product = data[productIndex];
        const item = product.daily_data[dayIndex];
        try {
            await axiosInstance.post(`/spends/${product.product_id}`, {
                date: item.date,
                spend: parseFloat(item.spend) || 0,
            });
            addNotification("ROAS updated successfully", "success");
        } catch (error) {
            addErrorNotifications(error.response?.data);
        }
    };

    const handleAggregateSave = async () => {
        const payload = data.map((product) => ({
            product_id: product.product_id,
            daily_data: product.daily_data.map((item) => ({
                date: item.date,
                spend: parseFloat(item.spend) || 0,
            })),
        }));
        try {
            await axiosInstance.post("/spends/aggregate", { products: payload });
            addNotification("All spends aggregated successfully", "success");
        } catch (error) {
            addErrorNotifications(error.response?.data);
        }
    };

    const handlePageChange = (newPage) => {
        if (newPage > 0 && newPage <= pagination.total_pages) {
            const start = dayjs(startDate).format("YYYY-MM-DD");
            const end = dayjs(endDate).format("YYYY-MM-DD");
            fetchData(start, end, newPage);
        }
    };

    const handleMonthChange = (month) => {
        setSelectedMonth(month);
        if (month !== '') {
            const monthIndex = parseInt(month, 10);
            const currentMonth = dayjs().month();
            const newStartDate = dayjs().month(monthIndex).startOf('month');

            const newEndDate = monthIndex === currentMonth ? dayjs().endOf('day') : dayjs().month(monthIndex).endOf('month');

            setStartDate(newStartDate.toDate());
            setEndDate(newEndDate.toDate());
        }
    };

    const handleTotalRoas = product => {
        const totalRoas = product.daily_data.reduce((sum, item) => {
            return sum + (item.roas || 0)
        }, 0)

        const roasCount = product.daily_data.filter(item => item.roas).length

        return roasCount > 0 ? totalRoas / roasCount : 0
    }

    if (loading) {
        return (
            <Flex justify="center" align="center" height="100vh">
                <Spinner size="xl" />
            </Flex>
        );
    }

    return (
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }} px={{ base: "20px", md: "40px" }}>
            <form autoComplete="off">
                <Card mb="20px" px={{ base: 4, md: 6 }} py={{ base: 4, md: 6 }}>
                    <Flex
                        direction={{ base: "column", md: "row" }}
                        alignItems={{ base: "center", md: "flex-end" }}
                        justifyContent="space-between"
                        gap="20px"
                    >
                        <Flex direction={{ base: "column", md: "row" }} gap="20px">
                            <FormControl>
                                <FormLabel>Start Date</FormLabel>
                                <DatePicker
                                    style={{zIndex: 9}}
                                    dateFormat="dd-MM-yyyy"
                                    selected={startDate}
                                    onChange={(date) => setStartDate(date)}
                                    customInput={
                                        <Input/>
                                    }
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>End Date</FormLabel>
                                <DatePicker
                                    dateFormat="dd-MM-yyyy"
                                    selected={endDate}
                                    onChange={(date) => setEndDate(date)}
                                    customInput={<Input />}
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Select Month</FormLabel>
                                <Select value={selectedMonth} onChange={(e) => handleMonthChange(e.target.value)}>
                                    <option value="">Select Month</option>
                                    <option value="0">January</option>
                                    <option value="1">February</option>
                                    <option value="2">March</option>
                                    <option value="3">April</option>
                                    <option value="4">May</option>
                                    <option value="5">June</option>
                                    <option value="6">July</option>
                                    <option value="7">August</option>
                                    <option value="8">September</option>
                                    <option value="9">October</option>
                                    <option value="10">November</option>
                                    <option value="11">December</option>
                                </Select>
                            </FormControl>
                        </Flex>
                        <Button colorScheme="blue" onClick={handleAggregateSave}>
                            Save All
                        </Button>
                    </Flex>
                </Card>
            </form>

            <SimpleGrid columns={{ base: 1, md: 2 }} spacing="20px">
                {data.map((product, productIndex) => (
                    <Card key={product.product_id} bg={cardBg} p="20px" boxShadow="lg" mb={productIndex === 2 || productIndex === 3 ? "50px" : "0px"}>
                        <Text fontSize="xl" fontWeight="bold" mb="10px" color={textColor}>
                            {product.product_name}
                        </Text>
                        <Box overflowX="auto" overflowY="auto" maxHeight="30vh">
                            <Table variant="simple" size="sm">
                                <Thead>
                                    <Tr>
                                        <Th color={textColor}
                                            borderBottomWidth="2px"
                                            borderColor={borderColor}
                                            position="sticky"
                                            top="0"
                                            zIndex="1"
                                            bg={cardBg}
                                        >
                                            Day
                                        </Th>
                                        <Th color={textColor}
                                            borderBottomWidth="2px"
                                            borderColor={borderColor}
                                            position="sticky"
                                            top="0"
                                            zIndex="1"
                                            bg={cardBg}
                                        >
                                            Spend
                                        </Th>
                                        <Th color={textColor}
                                            borderBottomWidth="2px"
                                            borderColor={borderColor}
                                            position="sticky"
                                            top="0"
                                            zIndex="1"
                                            bg={cardBg}
                                        >
                                            Sales
                                        </Th>
                                        <Th color={textColor}
                                            borderBottomWidth="2px"
                                            borderColor={borderColor}
                                            position="sticky"
                                            top="0"
                                            zIndex="1"
                                            bg={cardBg}
                                        >
                                            ROAS
                                        </Th>
                                        <Th color={textColor}
                                            borderBottomWidth="2px"
                                            borderColor={borderColor}
                                            position="sticky"
                                            top="0"
                                            zIndex="1"
                                            bg={cardBg}
                                        >
                                            Orders
                                        </Th>
                                        <Th color={textColor}
                                            borderBottomWidth="2px"
                                            borderColor={borderColor}
                                            position="sticky"
                                            top="0"
                                            zIndex="1"
                                            bg={cardBg}
                                        >
                                            Actions
                                        </Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {product.daily_data.map((item, dayIndex) => (
                                        <Tr key={`${product.product_id}-${item.date}`}>
                                            <Td>{item.date}</Td>
                                            <Td>
                                                <Input
                                                    value={item.spend || ""}
                                                    onChange={(e) => handleSpendChange(e.target.value, productIndex, dayIndex)}
                                                    placeholder="Enter Spend"
                                                    type="number"
                                                    min="0"
                                                    size="sm"
                                                    borderColor={borderColor}
                                                />
                                            </Td>
                                            <Td>{item.sales}</Td>
                                            <Td>{item.roas}</Td>
                                            <Td>{item.orders}</Td>
                                            <Td>
                                                <Button
                                                    size="xs"
                                                    colorScheme="teal"
                                                    onClick={() => handleSaveSingle(productIndex, dayIndex)}
                                                >
                                                    Save
                                                </Button>
                                            </Td>
                                        </Tr>
                                    ))}
                                </Tbody>
                                <Tfoot>
                                    <Tr>
                                        <Th
                                            color={textColor}
                                            borderTopWidth="2px"
                                            borderColor={borderColor}
                                            position="sticky"
                                            bottom="-1px"
                                            zIndex="1"
                                            bg={cardBg}
                                        >
                                            Total
                                        </Th>
                                        <Th
                                            color={textColor}
                                            borderTopWidth="2px"
                                            borderColor={borderColor}
                                            position="sticky"
                                            bottom="-1px"
                                            zIndex="1"
                                            bg={cardBg}
                                        >
                                            {formatNumber(product.daily_data.reduce((sum, item) => sum + item.spend, 0))} RON
                                        </Th>
                                        <Th
                                            color={textColor}
                                            borderTopWidth="2px"
                                            borderColor={borderColor}
                                            position="sticky"
                                            bottom="-1px"
                                            zIndex="1"
                                            bg={cardBg}
                                        >
                                            {formatNumber(product.daily_data.reduce((sum, item) => sum + item.sales, 0))} RON
                                        </Th>
                                        <Th
                                            color={textColor}
                                            borderTopWidth="2px"
                                            borderColor={borderColor}
                                            position="sticky"
                                            bottom="-1px"
                                            zIndex="1"
                                            bg={cardBg}
                                        >
                                            {formatNumber(handleTotalRoas(product))}
                                        </Th>
                                        <Th
                                            color={textColor}
                                            borderTopWidth="2px"
                                            borderColor={borderColor}
                                            position="sticky"
                                            bottom="-1px"
                                            zIndex="1"
                                            bg={cardBg}
                                        >
                                            {formatNumber(product.daily_data.reduce((sum, item) => sum + item.orders, 0))}
                                        </Th>
                                        <Th
                                            color={textColor}
                                            borderTopWidth="2px"
                                            borderColor={borderColor}
                                            position="sticky"
                                            bottom="-1px"
                                            zIndex="1"
                                            bg={cardBg}
                                        >
                                        </Th>
                                    </Tr>
                                </Tfoot>
                            </Table>
                        </Box>
                    </Card>
                ))}
            </SimpleGrid>

            <Flex
                justifyContent="space-between"
                alignItems="center"
                position="fixed"
                bottom="0"
                left={leftOffset}
                right="0"
                bg="white"
                py="10px"
                px="20px"
                boxShadow="md"
                zIndex="1000"
            >
                <Button onClick={() => handlePageChange(pagination.current_page - 1)} disabled={pagination.current_page === 1}>
                    Previous
                </Button>
                <Text>
                    Page {pagination.current_page} of {pagination.total_pages}
                </Text>
                <Button onClick={() => handlePageChange(pagination.current_page + 1)} disabled={pagination.current_page === pagination.total_pages}>
                    Next
                </Button>
            </Flex>
        </Box>
    );
};

export default SpendDashboard;