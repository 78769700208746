import { Icon, Flex, Text, useColorModeValue } from "@chakra-ui/react";
import { MdUpgrade } from "react-icons/md";
import React from "react";

export function ItemContent(props) {
    const textColor = useColorModeValue("navy.700", "white");
    const dateColor = useColorModeValue("gray.500", "gray.300");

    return (
        <Flex alignItems="center">
            <Flex
                justify="center"
                align="center"
                borderRadius="16px"
                minH={{ base: "50px", md: "50px" }}
                h={{ base: "50px", md: "50px" }}
                minW={{ base: "50px", md: "50px" }}
                w={{ base: "50px", md: "50px" }}
                me="14px"
                bg="linear-gradient(135deg, #868CFF 0%, #4318FF 100%)"
            >
                <Icon as={MdUpgrade} color="white" w={8} h={14} />
            </Flex>
            <Flex flexDirection="column">
                <Text
                    mb="5px"
                    fontWeight="bold"
                    color={textColor}
                    fontSize={{ base: "md", md: "md" }}
                >
                    {props.title}
                </Text>
                <Text
                    fontSize={{ base: "sm", md: "sm" }}
                    lineHeight="100%"
                    color={textColor}
                >
                    {props.info}
                </Text>
                {props.date && (
                    <Text
                        mt="5px"
                        fontSize={{ base: "xs", md: "sm" }}
                        color={dateColor}
                        textAlign={{ base: "left", md: "left" }}
                    >
                        {props.date}
                    </Text>
                )}
            </Flex>
        </Flex>
    );
}