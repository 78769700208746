import React from 'react';
import {
    Box,
    Button,
    FormControl,
    FormLabel,
    Input,
    Flex,
    SimpleGrid,
    IconButton,
    Tooltip,
    Image,
} from '@chakra-ui/react';
import { DeleteIcon } from '@chakra-ui/icons';

const Step4Variations = ({ variations, setVariations }) => {
    const handleVariationChange = (index, field, value) => {
        const newVariations = [...variations];
        newVariations[index][field] = value;
        setVariations(newVariations);
    };

    const handleImageChange = (index, event) => {
        const file = event.target.files[0];
        const newVariations = [...variations];

        if (file) {
            newVariations[index].image = file;
            newVariations[index].imageUrl = URL.createObjectURL(file);
        } else {
            newVariations[index].image = null;
            newVariations[index].imageUrl = '';
        }

        setVariations(newVariations);
    };

    const addVariation = () => {
        setVariations([...variations, { size: '', color: '', image: null, imageUrl: '' }]);
    };

    const removeVariation = (index) => {
        const newVariations = variations.filter((_, i) => i !== index);
        setVariations(newVariations);
    };

    return (
        <Box>
            {variations.map((variation, index) => (
                <Box key={index} mb="8" p="4" borderWidth="1px" borderRadius="lg" boxShadow="sm">
                    <SimpleGrid columns={{ base: 1, md: 3 }} spacing="4" mb="4">
                        <FormControl>
                            <FormLabel>Size</FormLabel>
                            <Input
                                value={variation.size}
                                onChange={(e) => handleVariationChange(index, 'size', e.target.value)}
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Color</FormLabel>
                            <Input
                                value={variation.color}
                                onChange={(e) => handleVariationChange(index, 'color', e.target.value)}
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel>Upload Image</FormLabel>
                            <Input
                                type="file"
                                accept="image/*"
                                onChange={(e) => handleImageChange(index, e)}
                            />
                            {variation.imageUrl && (
                                <Box position="relative" mt="4">
                                    <Image
                                        src={variation.imageUrl}
                                        alt={`Variation ${index + 1}`}
                                        boxSize="100px"
                                        objectFit="cover"
                                        rounded="md"
                                    />
                                    <IconButton
                                        icon={<DeleteIcon />}
                                        aria-label="delete image"
                                        colorScheme="red"
                                        position="absolute"
                                        top="0"
                                        right="0"
                                        onClick={() => handleImageChange(index, { target: { files: [null] } })}
                                    />
                                </Box>
                            )}
                        </FormControl>
                    </SimpleGrid>

                    <Flex alignItems="center" mt="4">
                        <Tooltip label="Delete Variation">
                            <IconButton
                                icon={<DeleteIcon />}
                                onClick={() => removeVariation(index)}
                                colorScheme="red"
                            />
                        </Tooltip>
                    </Flex>
                </Box>
            ))}
            <Button colorScheme="blue" onClick={addVariation}>
                Add Variation
            </Button>
        </Box>
    );
};

export default Step4Variations;