import React, { useState, useEffect } from 'react';
import axiosInstance from '../../../api/axiosInstance';
import { useParams, useNavigate } from 'react-router-dom';
import {
    Box,
    Heading,
    FormControl,
    FormLabel,
    Input,
    Button,
    Text,
    Flex,
    Select,
    SimpleGrid,
    IconButton,
    Tooltip,
    Image,
} from '@chakra-ui/react';
import { DeleteIcon } from '@chakra-ui/icons';
import { useNotification } from '../../../contexts/NotificationContext';

const FormEditor = () => {
    const { formId, productId } = useParams();
    const navigate = useNavigate();
    const [form, setForm] = useState({
        fields: [],
        description: '',
        shipping_label: '',
        shipping_field: '',
        shipping_value: '',
        upsells: [],
    });
    const [products, setProducts] = useState([]);
    const { addNotification, addErrorNotifications } = useNotification();
    const fieldOptions = ['name', 'phone', 'county', 'city', 'address'];

    useEffect(() => {
        if (formId) {
            const fetchForm = async () => {
                const response = await axiosInstance.get(`/forms/${formId}`);
                setForm(response.data);
            };
            fetchForm();
        }

        const fetchProducts = async () => {
            const response = await axiosInstance.get(`/products/dictionary`);
            setProducts(response.data);
        };
        fetchProducts();
    }, [formId]);

    const handleFieldChange = (index, field, value) => {
        const newFields = [...form.fields];
        newFields[index][field] = value;
        setForm({ ...form, fields: newFields });
    };

    const handleUpsellChange = (index, field, value) => {
        const newUpsells = [...form.upsells];
        newUpsells[index][field] = value;
        setForm({ ...form, upsells: newUpsells });
    };

    const handleImageChange = (index, event) => {
        const file = event.target.files[0];

        const newUpsells = [...form.upsells];
        if (file) {
            newUpsells[index].image = file;
            newUpsells[index].image_url = URL.createObjectURL(file);
        } else {
            newUpsells[index].image = null;
            newUpsells[index].image_url = '';
        }

        setForm({ ...form, upsells: newUpsells });
    };

    const addField = () => {
        setForm({
            ...form,
            fields: [...form.fields, { name: '', label: '', description: '', type: 'text' }],
        });
    };

    const removeField = (index) => {
        const newFields = form.fields.filter((_, i) => i !== index);
        setForm({ ...form, fields: newFields });
    };

    const addUpsell = () => {
        setForm({
            ...form,
            upsells: [
                ...form.upsells,
                { product_id: '', value: '', image: null, image_url: '', full_text: '' },
            ],
        });
    };

    const removeUpsell = (index) => {
        const newUpsells = form.upsells.filter((_, i) => i !== index);
        setForm({ ...form, upsells: newUpsells });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('description', form.description);
        formData.append('shipping_label', form.shipping_label);
        formData.append('shipping_field', form.shipping_field);
        formData.append('shipping_value', form.shipping_value);
        formData.append('product_id', productId);

        form.fields.forEach((field, index) => {
            formData.append(`fields[${index}][name]`, field.name);
            formData.append(`fields[${index}][label]`, field.label);
            formData.append(`fields[${index}][description]`, field.description);
            formData.append(`fields[${index}][type]`, field.type);
        });

        form.upsells.forEach((upsell, index) => {
            formData.append(`upsells[${index}][product_id]`, upsell.product_id);
            formData.append(`upsells[${index}][value]`, upsell.value);
            formData.append(`upsells[${index}][full_text]`, upsell.full_text);
            if (upsell.image) {
                formData.append(`upsells[${index}][image]`, upsell.image);
            }

            if (upsell.image_url) {
                formData.append(`upsells[${index}][image_url]`, upsell.image_url);
            }
        });

        try {
            if (formId) {
                formData.append(`_method`, 'PUT');
                await axiosInstance.post(`/forms/${formId}`, formData);
                addNotification('Form updated successfully', 'success');
            } else {
                await axiosInstance.post('/forms', formData);
                addNotification('Form created successfully', 'success');
            }
            navigate('/admin/products');
        } catch (error) {
            addErrorNotifications(error.response?.data);
        }
    };

    const getAvailableOptions = (currentName) => {
        const selectedNames = form.fields.map((field) => field.name);
        return fieldOptions.filter((option) => !selectedNames.includes(option) || option === currentName);
    };

    return (
        <Box
            maxWidth="xxl"
            px={{ base: '20px', md: '40px' }}
            py={{ base: '130px', md: '80px' }}
            bg="white"
            boxShadow="lg"
            rounded="md"
        >
            <form onSubmit={handleSubmit}>
                <FormControl mb="4">
                    <FormLabel>Description</FormLabel>
                    <Input
                        value={form.description}
                        onChange={(e) => setForm({ ...form, description: e.target.value })}
                    />
                </FormControl>
                <FormControl mb="4">
                    <FormLabel>Shipping Label</FormLabel>
                    <Input
                        value={form.shipping_label}
                        onChange={(e) => setForm({ ...form, shipping_label: e.target.value })}
                    />
                </FormControl>
                <FormControl mb="4">
                    <FormLabel>Shipping Field</FormLabel>
                    <Input
                        value={form.shipping_field}
                        onChange={(e) => setForm({ ...form, shipping_field: e.target.value })}
                    />
                </FormControl>
                <FormControl mb="4">
                    <FormLabel>Shipping Value</FormLabel>
                    <Input
                        type="number"
                        value={form.shipping_value}
                        onChange={(e) => setForm({ ...form, shipping_value: e.target.value })}
                    />
                </FormControl>

                <Box mb="8">
                    <Text fontSize="xl" mb="4">
                        Fields
                    </Text>
                    {form.fields.map((field, index) => (
                        <SimpleGrid columns={{ base: 1, md: 5 }} spacing="4" mb="4" key={index}>
                            <FormControl>
                                <FormLabel>Name</FormLabel>
                                <Select
                                    value={field.name}
                                    onChange={(e) => handleFieldChange(index, 'name', e.target.value)}
                                    placeholder="Select a field"
                                >
                                    {getAvailableOptions(field.name).map((option) => (
                                        <option key={option} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControl>
                                <FormLabel>Label</FormLabel>
                                <Input
                                    value={field.label}
                                    onChange={(e) => handleFieldChange(index, 'label', e.target.value)}
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Description</FormLabel>
                                <Input
                                    value={field.description}
                                    onChange={(e) => handleFieldChange(index, 'description', e.target.value)}
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Type</FormLabel>
                                <Select
                                    value={field.type}
                                    onChange={(e) => handleFieldChange(index, 'type', e.target.value)}
                                >
                                    <option value="text">Text</option>
                                    <option value="tel">Number</option>
                                </Select>
                            </FormControl>
                            <Flex alignItems="center" justifyContent="center">
                                <Tooltip label="Delete Field">
                                    <IconButton
                                        icon={<DeleteIcon />}
                                        aria-label="delete"
                                        onClick={() => removeField(index)}
                                        colorScheme="red"
                                    />
                                </Tooltip>
                            </Flex>
                        </SimpleGrid>
                    ))}
                    <Button colorScheme="blue" onClick={addField}>
                        Add Field
                    </Button>
                </Box>

                <Box mb="8">
                    <Text fontSize="xl" mb="4">
                        Upsell Products
                    </Text>
                    {form.upsells.map((upsell, index) => (
                        <SimpleGrid columns={{ base: 1, md: 5 }} spacing="4" mb="4" key={index}>
                            <FormControl>
                                <FormLabel>Product</FormLabel>
                                <Select
                                    value={upsell.product_id}
                                    onChange={(e) => handleUpsellChange(index, 'product_id', e.target.value)}
                                >
                                    {products.map((product) => (
                                        <option key={product.id} value={product.id}>
                                            {product.name}
                                        </option>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControl>
                                <FormLabel>Price</FormLabel>
                                <Input
                                    type="number"
                                    value={upsell.value}
                                    onChange={(e) => handleUpsellChange(index, 'value', e.target.value)}
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Full Text</FormLabel>
                                <Input
                                    value={upsell.full_text}
                                    onChange={(e) => handleUpsellChange(index, 'full_text', e.target.value)}
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Image</FormLabel>
                                <Input
                                    type="file"
                                    accept="image/*"
                                    onChange={(e) => handleImageChange(index, e)}
                                />
                                {upsell.image_url && (
                                    <Box position="relative" mt="4">
                                        <Image
                                            src={upsell.image_url}
                                            alt="option"
                                            boxSize="100px"
                                            objectFit="cover"
                                            rounded="md"
                                        />
                                        <IconButton
                                            icon={<DeleteIcon />}
                                            aria-label="delete image"
                                            colorScheme="red"
                                            position="absolute"
                                            top="0"
                                            right="0"
                                            onClick={() => handleImageChange(index, { target: { files: [null] } })}
                                        />
                                    </Box>
                                )}
                            </FormControl>
                            <Flex alignItems="center" justifyContent="center">
                                <Tooltip label="Delete Upsell">
                                    <IconButton
                                        icon={<DeleteIcon />}
                                        aria-label="delete upsell"
                                        onClick={() => removeUpsell(index)}
                                        colorScheme="red"
                                    />
                                </Tooltip>
                            </Flex>
                        </SimpleGrid>
                    ))}
                    <Button colorScheme="blue" onClick={addUpsell}>
                        Add Upsell
                    </Button>
                </Box>

                <Button type="submit" colorScheme="teal" mt="6">
                    Save
                </Button>
            </form>
        </Box>
    );
};

export default FormEditor;