import {
  Flex,
  Box,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";

import Card from "components/card/Card";
import PieChart from "components/charts/PieChart";
import { VSeparator } from "components/separator/Separator";
import {formatNumber} from "../../../Utils/Utils";

export default function Conversion(props) {
  const { data } = props;
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const cardColor = useColorModeValue("white", "navy.700");
  const cardShadow = useColorModeValue(
      "0px 18px 40px rgba(112, 144, 176, 0.12)",
      "unset"
  );

  const pieChartData = data.map((item) => parseFloat(item.total_amount));
  const pieChartLabels = data.map((item) => item.status);

  const pieChartOptions = {
    labels: pieChartLabels,
    colors: ["#4318FF", "#6AD2FF", "#2f3031"],
    chart: {
      width: "300px",
      height: "300px",
    },
    states: {
      hover: {
        filter: {
          type: "none",
        },
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    hover: { mode: null },
    plotOptions: {
      donut: {
        expandOnClick: false,
        donut: {
          labels: {
            show: false,
          },
        },
      },
    },
    fill: {
      colors: ["#4318FF", "#6AD2FF", "#EFF4FB"],
    },
    tooltip: {
      enabled: true,
      theme: "dark",
    },
  };

  return (
      <Card p="20px" align="center" direction="column" w="100%">
        <Flex
            px={{ base: "0px", "2xl": "10px" }}
            justifyContent="space-between"
            alignItems="center"
            w="100%"
            mb="8px"
        >
          <Text color={textColor} fontSize="md" fontWeight="600" mt="4px">
            Orders summary
          </Text>
        </Flex>

        <PieChart
            h="400px"
            w="400px"
            chartData={pieChartData}
            chartOptions={pieChartOptions}
        />
        <Card
            bg={cardColor}
            flexDirection="row"
            boxShadow={cardShadow}
            w="100%"
            p="15px"
            px="20px"
            mt="15px"
            mx="auto"
        >
          {data.map((item, index) => (
              <React.Fragment key={index}>
                <Flex direction="column" py="5px" me="10px">
                  <Flex align="center">
                    <Box
                        h="8px"
                        w="8px"
                        bg={pieChartOptions.colors[index]}
                        borderRadius="50%"
                        me="4px"
                    />
                    <Text
                        fontSize="xs"
                        color="secondaryGray.600"
                        fontWeight="700"
                        mb="5px"
                    >
                      {item.status}
                    </Text>
                  </Flex>
                  <Text fontSize="lg" color={textColor} fontWeight="700">
                    {formatNumber(item.total_amount)} RON ({item.count} orders)
                  </Text>
                </Flex>
                {index < data.length - 1 && (
                    <VSeparator
                        mx={{ base: "10px", xl: "20px", "2xl": "30px" }}
                    />
                )}
              </React.Fragment>
          ))}
        </Card>
      </Card>
  );
}