import React from "react";
import {
    Box,
    Button,
    Flex,
    Icon,
    Text,
    useColorModeValue,
    Spinner,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
import BarChart from "components/charts/BarChart";
import { MdBarChart } from "react-icons/md";

export default function BestSellingOptionsChart({ productSales, ...rest }) {
    const textColor = useColorModeValue("secondaryGray.900", "white");
    const iconColor = useColorModeValue("brand.500", "white");
    const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
    const bgHover = useColorModeValue(
        { bg: "secondaryGray.400" },
        { bg: "whiteAlpha.50" }
    );
    const bgFocus = useColorModeValue(
        { bg: "secondaryGray.300" },
        { bg: "whiteAlpha.100" }
    );

    // Construct the bar chart data using the best option for each product
    const barChartData = [
        {
            name: "Best-Selling Options",
            data: productSales.map((product) => product.unitsSold || 0),
        },
    ];

    // Categories include product name along with the best-selling option for clarity
    const categories = productSales.map((product) => {
        return `${product.name} (Best Option: ${product.bestOption || 'N/A'})`;
    });

    // Bar chart options for improved UI/UX
    const barChartOptions = {
        chart: {
            toolbar: {
                show: false,
            },
            height: 350,
            zoom: {
                enabled: false,
            },
        },
        tooltip: {
            style: {
                fontSize: "12px",
                fontFamily: undefined,
            },
            theme: "dark",
            y: {
                formatter: (val, { seriesIndex, dataPointIndex }) => {
                    const product = productSales[dataPointIndex];
                    return `${product.bestOption || 'N/A'} (${val} units sold)`;
                }
            }
        },
        xaxis: {
            categories: categories,
            show: true,
            labels: {
                style: {
                    colors: "#A3AED0",
                    fontSize: "14px",
                    fontWeight: "500",
                },
                formatter: function (value) {
                    return value.length > 15 ? `${value.substring(0, 15)}...` : value;
                }
            },
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
        },
        yaxis: {
            labels: {
                show: true,
                style: {
                    colors: "#A3AED0",
                    fontSize: "14px",
                }
            }
        },
        grid: {
            show: false,
            strokeDashArray: 5,
            yaxis: {
                lines: {
                    show: true,
                },
            },
            xaxis: {
                lines: {
                    show: false,
                },
            },
        },
        fill: {
            type: "gradient",
            gradient: {
                type: "vertical",
                shadeIntensity: 1,
                opacityFrom: 0.7,
                opacityTo: 0.9,
            },
        },
        dataLabels: {
            enabled: true,
            formatter: function (value) {
                return value;
            },
            style: {
                colors: ["#fff"],
                fontSize: "12px"
            },
            offsetY: -10
        },
        plotOptions: {
            bar: {
                borderRadius: 10,
                columnWidth: "60%",
            },
        },
        colors: ["#5E37FF"],
    };

    return (
        <Card align="center" direction="column" w="100%" {...rest}>
            <Flex justify="space-between" align="start" px="10px" pt="5px">
                <Flex flexDirection="column" align="start" me="20px">
                    <Text
                        color="secondaryGray.600"
                        fontSize="sm"
                        fontWeight="500"
                    >
                        Best-Selling Options by Product
                    </Text>
                </Flex>
                <Button
                    align="center"
                    justifyContent="center"
                    bg={bgButton}
                    _hover={bgHover}
                    _focus={bgFocus}
                    _active={bgFocus}
                    w="37px"
                    h="37px"
                    lineHeight="100%"
                    borderRadius="10px"
                    {...rest}
                >
                    <Icon as={MdBarChart} color={iconColor} w="24px" h="24px" />
                </Button>
            </Flex>

            <Box h="240px" mt="auto">
                {productSales.length > 0 ? (
                    <BarChart
                        chartData={barChartData}
                        chartOptions={barChartOptions}
                    />
                ) : (
                    <Text color="secondaryGray.600" mt="8">
                        No data available.
                    </Text>
                )}
            </Box>
        </Card>
    );
}