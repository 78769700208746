import React, { useEffect } from 'react';
import { useToast, Box } from '@chakra-ui/react';
import { useNotification } from '../../contexts/NotificationContext';

const Notification = () => {
    const { notifications, removeNotification } = useNotification();
    const toast = useToast();

    useEffect(() => {
        notifications.forEach((notification) => {
            if (!toast.isActive(notification.key)) {
                toast({
                    id: notification.key,
                    title: notification.message,
                    status: notification.severity,
                    duration: 6000,
                    isClosable: true,
                    position: 'bottom-right',
                    onCloseComplete: () => removeNotification(notification.key),
                });
            }
        });
    }, [notifications, removeNotification, toast]);

    return <Box />;
};

export default Notification;
