import React, { useState, useEffect } from 'react';
import {
    Box,
    Flex,
    IconButton,
    Text,
    Select,
    FormControl,
    FormLabel,
    Checkbox,
    Table,
    Thead,
    Tr,
    Th,
    Tbody,
    Td,
    Input,
    SimpleGrid, Badge, Button, Icon, Tooltip,
} from '@chakra-ui/react';
import {MdDelete, MdVisibility, MdBlock, MdSync, MdCheckCircle, MdCancel} from 'react-icons/md';
import { useNotification } from '../../../contexts/NotificationContext';
import axiosInstance from '../../../api/axiosInstance';
import dayjs from 'dayjs';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Pagination from './Pagination';

import ConfirmationDialog from './ConfirmationDialog';
import UpsellModal from './UpsellModal';

import Card from 'components/card/Card';
import { Global } from "@emotion/react";
import {WarningIcon} from "@chakra-ui/icons";

const ReportsPage = () => {
    const [ordersByDate, setOrdersByDate] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [totalOrders, setTotalOrders] = useState(0);
    const [products, setProducts] = useState([]);
    const [selectedProduct, setSelectedProduct] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [shouldSearch, setShouldSearch] = useState(0);
    const [selectedOrders, setSelectedOrders] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [orderToDelete, setOrderToDelete] = useState(null);
    const [openUpsellModal, setOpenUpsellModal] = useState(false);
    const [upsellDetails, setUpsellDetails] = useState([]);
    const [upsellAmount, setUpsellAmount] = useState('');
    const { addNotification, addErrorNotifications } = useNotification();
    const [usage, setUsage] = useState([])
    const [blackList, setBlackList] = useState([])

    useEffect(() => {
        fetchOrdersByDate(currentPage);
    }, [currentPage]);

    useEffect(() => {
        fetchOrdersByDate(1);
    }, [startDate, endDate]);

    useEffect(() => {
        if (!shouldSearch) return;
        fetchOrdersByDate(1);
    }, [shouldSearch]);

    const fetchOrdersByDate = async (page = currentPage) => {
        try {
            const response = await axiosInstance.get('/reports/orders-by-date', {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
                params: {
                    start_date: startDate ? dayjs(startDate).format('YYYY-MM-DD') : null,
                    end_date: endDate ? dayjs(endDate).format('YYYY-MM-DD') : null,
                    page: page,
                    product_id: selectedProduct,
                    searchTerm: searchTerm,
                },
            });
            setOrdersByDate(response.data.orders);
            setTotalPages(response.data.total_pages);
            setTotalOrders(response.data.order_count);
            setProducts(response.data.products);

            await fetchInfoOrders(response.data.orders)
        } catch (error) {
            addErrorNotifications(error.response?.data);
        }
    };

    const handlePageChange = (value) => {
        setCurrentPage(value);
    };

    const handleSearchDatabase = (event) => {
        if (event.key === 'Enter') {
            setShouldSearch((prev) => prev + 1);
        }
    };

    const handleSelectOrder = (orderId) => {
        setSelectedOrders((prevSelected) =>
            prevSelected.includes(orderId)
                ? prevSelected.filter((id) => id !== orderId)
                : [...prevSelected, orderId]
        );
    };

    const handleClickOpen = (order) => {
        setOrderToDelete(order);
        setOpenDialog(true);
    };

    const handleClose = () => {
        setOpenDialog(false);
        setOrderToDelete(null);
    };

    const handleConfirmDelete = async () => {
        if (orderToDelete) {
            try {
                await axiosInstance.delete(`/reports/orders/${orderToDelete.id}`, {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
                });
                addNotification('Order deleted successfully', 'success');
                fetchOrdersByDate(currentPage);
                handleClose();
            } catch (error) {
                addErrorNotifications(error.response?.data);
            }
        }
    };

    const handleUpsellModalOpen = (upsellDetails, upsellAmount) => {
        setUpsellDetails(upsellDetails);
        setUpsellAmount(upsellAmount);
        setOpenUpsellModal(true);
    };

    const handleBlock = async (order) => {
        await axiosInstance.post('/reports/block-ip', { ip: order.ip_address })
            .then(() => addNotification(`${order.ip_address} has been blocked`, 'success'));
    };

    const buildOrderName = (order) => {
        let name = `${order.option_name}`;
        const metadata = JSON.parse(order.metadata);

        if (metadata) {
            Object.values(metadata).forEach((meta) => {
                name = name + `<br />${meta.quantity} - ${meta.color}`;
            });
        }

        return <div dangerouslySetInnerHTML={{ __html: name }} />;
    };

    const handleSelectProduct = async (event) => {
        const value = event.target.value === 'all' ? '' : event.target.value
        setSelectedProduct(value)

        const response = await axiosInstance.get('/reports/orders-by-date', {
            headers: {'Authorization': `Bearer ${localStorage.getItem('token')}`},
            params: {
                start_date: startDate ? dayjs(startDate).format('YYYY-MM-DD') : null,
                end_date: endDate ? dayjs(endDate).format('YYYY-MM-DD') : null,
                page: 1,
                product_id: value,
                searchTerm: searchTerm,
            },
        })

        setOrdersByDate(response.data.orders)
        setTotalPages(response.data.total_pages)
        setProducts(response.data.products)
        setTotalOrders(response.data.order_count)

        await fetchInfoOrders(response.data.orders)
    }

    const fetchInfoOrders = async (orders) => {
        const phones = orders.map((order) => order.phone)

        await axiosInstance
            .post('/reports/get-info', {phones: phones})
            .then((response) => {
                setUsage(response.data.usage)
                setBlackList(response.data.blacklist)
            })
            .catch((error) => {
                console.error('Error fetching phone usage:', error)
            })
    }

    const getOrderCountByPhone = (phone) => {
        const phoneUsageEntry = usage.find((entry) => entry.normalized_phone === phone.replace(/\s+/g, '').slice(-10))
        return phoneUsageEntry ? phoneUsageEntry.order_count : 0
    }

    const getOrderBlackListed = order => {
        const blacklisted = blackList.find((entry) => entry.order_id === order.id || entry.phone === order.phone)
        return !!blacklisted
    }

    const handleDeleteSelected = async () => {
        try {
            await axiosInstance.post(
                '/reports/orders/batch-delete',
                {ids: selectedOrders},
                {
                    headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
                }
            )

            fetchOrdersByDate()
            setSelectedOrders([])
            addNotification('Orders deleted successfully', 'success');
        } catch (error) {
            console.error('Error deleting orders:', error)
            addErrorNotifications("Orders couldn't be deleted successfully", 'success');
        }
    }

    const handleSyncSelected = async () => {
        try {
            await axiosInstance.post(
                '/reports/orders/batch-sync',
                {ids: selectedOrders},
                {
                    headers: {Authorization: `Bearer ${localStorage.getItem('token')}`},
                }
            )

            fetchOrdersByDate()
            setSelectedOrders([])
            addNotification('Orders synced successfully', 'success');
        } catch (error) {
            console.error('Error syncing orders:', error);
            addErrorNotifications("Orders couldn't be synced successfully", 'success');
        }
    }

    return (
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }} maxWidth="xxl" px={{ base: '20px', md: '40px' }}>
            <Card mb="20px" px={{ base: 4, md: 6 }}>
                <Global
                    styles={`
                    .react-datepicker-wrapper {
                      display: inherit;
                    }
                `} />
                <SimpleGrid columns={{ base: 1, md: 2, lg: 3, xl: 6 }} spacing="20px">
                    <FormControl>
                        <FormLabel>Start Date</FormLabel>
                        <DatePicker
                            dateFormat="dd-MM-yyyy"
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            customInput={<Input />}
                        />
                    </FormControl>
                    <FormControl>
                        <FormLabel>End Date</FormLabel>
                        <DatePicker
                            dateFormat="dd-MM-yyyy"
                            selected={endDate}
                            onChange={(date) => setEndDate(date)}
                            customInput={<Input />}
                        />
                    </FormControl>
                    <FormControl>
                        <FormLabel>Product</FormLabel>
                        <Select placeholder="Select Product" onChange={handleSelectProduct}>
                            <option value="all">All Products</option>
                            {products.map((product) => (
                                <option key={product.id} value={product.id}>
                                    {product.name}
                                </option>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl>
                        <FormLabel>Search</FormLabel>
                        <Input
                            placeholder="Search"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            onKeyPress={handleSearchDatabase}
                        />
                    </FormControl>
                    {selectedOrders.length > 0 && (
                        <Box
                            gridColumn={{ base: "span 1", md: "span 2" }}
                            textAlign={{ base: "center", md: "right" }}
                            mt={{ base: "20px", md: "0" }}
                        >
                            <Tooltip label="Delete selected orders">
                                <Button
                                    colorScheme="red"
                                    leftIcon={<Icon as={MdDelete} />}
                                    onClick={handleDeleteSelected}
                                    mr={{ base: "0", md: "10px" }}
                                    mb={{ base: "10px", md: "0" }}
                                    width={{ base: "full", md: "auto" }}
                                >
                                    Delete
                                </Button>
                            </Tooltip>
                            <Tooltip label="Sync selected orders with woocommerce">
                                <Button
                                    colorScheme="blue"
                                    leftIcon={<Icon as={MdSync} />}
                                    onClick={handleSyncSelected}
                                    width={{ base: "full", md: "auto" }}
                                >
                                    Sync
                                </Button>
                            </Tooltip>
                        </Box>
                    )}
                </SimpleGrid>
            </Card>
            <Card>
                <Box overflowX="auto">
                    <Box display="flex" justifyContent="space-between" mb="4" p="4">
                        <Text fontSize="2xl" fontWeight="bold">
                            Orders - {totalOrders}
                        </Text>
                    </Box>
                    <Table variant="simple" size="sm" minW="100%">
                        <Thead>
                            <Tr>
                                <Th>
                                    <Checkbox
                                        isChecked={selectedOrders.length === ordersByDate.length}
                                        onChange={(e) =>
                                            setSelectedOrders(
                                                e.target.checked ? ordersByDate.map((order) => order.id) : []
                                            )
                                        }
                                    />
                                </Th>
                                <Th>ID</Th>
                                <Th>Created At</Th>
                                <Th>Product</Th>
                                <Th>Option</Th>
                                <Th>Name</Th>
                                <Th>Phone</Th>
                                <Th>County</Th>
                                <Th>City</Th>
                                <Th>Address</Th>
                                <Th>Total</Th>
                                <Th>Variation</Th>
                                <Th>Status</Th>
                                <Th>Synced</Th>
                                <Th>Actions</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {ordersByDate.map((order) => (
                                <Tr key={order.id} style={{backgroundColor: getOrderBlackListed(order) ? '#FFCDD2' : 'inherit'}}>
                                    <Td>
                                        <Checkbox
                                            isChecked={selectedOrders.includes(order.id)}
                                            onChange={() => handleSelectOrder(order.id)}
                                        />
                                    </Td>
                                    <Td>{order.id}</Td>
                                    <Td>{dayjs(order.created_at).format('DD MMM YYYY HH:mm')}</Td>
                                    <Td>{`${order.quantity} x ${order.product?.name}`}</Td>
                                    <Td>{buildOrderName(order)}</Td>
                                    <Td>
                                        <Flex alignItems="center">
                                            <Tooltip label={order.ip_address} aria-label="Block User Tooltip">
                                                <Text>{order.name}</Text>
                                            </Tooltip>
                                            {order.blocked_ip && (
                                                <Tooltip label="This IP is blocked" aria-label="Blocked IP Tooltip">
                                                    <Icon as={WarningIcon} color="red.500" ml={2} />
                                                </Tooltip>
                                            )}
                                        </Flex>
                                    </Td>
                                    <Td>
                                        <span style={{marginRight: 10}}>{order.phone}</span>
                                        {getOrderCountByPhone(order.phone) > 1 ? (
                                                <Badge
                                                    colorScheme="green"
                                                    borderRadius="full"
                                                    px="2.5"
                                                    fontSize="0.8em"
                                                >
                                                    {getOrderCountByPhone(
                                                        order.phone
                                                    )}
                                                </Badge>
                                        ) : (
                                            ''
                                        )}
                                    </Td>
                                    <Td>{order.county}</Td>
                                    <Td>{order.city}</Td>
                                    <Td>{order.address}</Td>
                                    <Td>{order.total}</Td>
                                    <Td>{order.variation_name ? order.variation_name : '-'}</Td>
                                    <Td>
                                        <Text color={order.status === 'completed' ? 'green.500' : 'orange.500'}>
                                            {order.status}
                                        </Text>
                                    </Td>
                                    <Td>
                                        <Icon
                                            w="24px"
                                            h="24px"
                                            me="5px"
                                            color={
                                                order.synced
                                                    ? 'green.500'
                                                    : 'red.500'
                                            }
                                            as={
                                                order.synced
                                                    ? MdCheckCircle
                                                    : MdCancel
                                            }
                                        />
                                    </Td>
                                    <Td>
                                        <Flex>
                                            {order.upsell_amount > 0 && (
                                                <IconButton
                                                    aria-label="view upsell"
                                                    icon={<MdVisibility />}
                                                    onClick={() =>
                                                        handleUpsellModalOpen(order.upsell_details, order.upsell_amount)
                                                    }
                                                    mr="2"
                                                />
                                            )}
                                            <Tooltip label="Block User" aria-label="Block User Tooltip">
                                                <IconButton
                                                    aria-label="block"
                                                    icon={<MdBlock />}
                                                    onClick={() => handleBlock(order)}
                                                    mr="2"
                                                />
                                            </Tooltip>
                                            <Tooltip label="Delete Order" aria-label="Delete Order Tooltip">
                                                <IconButton
                                                    aria-label="delete"
                                                    icon={<MdDelete />}
                                                    onClick={() => handleClickOpen(order)}
                                                />
                                            </Tooltip>
                                        </Flex>
                                    </Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </Box>
                <Flex justifyContent="space-between" mt="4" flexWrap="wrap">
                    <Text mb={{ base: '8px', md: '0' }}>
                        Showing {Math.min(totalOrders, ordersByDate.length)} from {totalOrders} orders
                    </Text>
                    <Pagination totalPages={totalPages} currentPage={currentPage} onPageChange={handlePageChange} />
                </Flex>
            </Card>
            <UpsellModal
                open={openUpsellModal}
                handleClose={() => setOpenUpsellModal(false)}
                upsellDetails={upsellDetails}
                upsellAmount={upsellAmount}
            />
            <ConfirmationDialog
                open={openDialog}
                handleClose={handleClose}
                handleConfirm={handleConfirmDelete}
                title="Delete confirmation"
                description="Are you sure you want to delete this?"
            />
        </Box>
    );
};

export default ReportsPage;